import React from 'react';

type ProgressBarProps = {
    bgcolor: string;
    completed: number;
};

const ProgressBar = ({ bgcolor, completed }: ProgressBarProps): JSX.Element => {
    const containerStyles = {
        height: '5px',
        width: '100%',
        backgroundColor: '#e0e0de',
        borderRadius: 50,
    } as React.CSSProperties;

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 0.3s ease-in-out',
    } as React.CSSProperties;

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}></div>
        </div>
    );
};

export default ProgressBar;
