import React from 'react';
import { Modal as ModalWrapper } from '@components/UI/Modal';
import Button from '@components/common/button/Button';

import styles from './ModalWindow.module.scss';

export const Modal = ({
    message,
    isOpen,
    btnLabel,
    btnAction,
    onClose,
}: {
    message: string;
    isOpen: boolean;
    btnLabel: string;
    btnAction: () => void;
    onClose: () => void;
}): JSX.Element => {
    return (
        <ModalWrapper isOpen={isOpen} onClose={onClose} title="Удаление">
            <div className={styles.Content}>
                <span className={styles.Message}>{message}</span>
            </div>
            <div className={styles.ButtonsContainer}>
                <Button onClick={btnAction} title={btnLabel} />
            </div>
        </ModalWrapper>
    );
};
