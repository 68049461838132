import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function FolderIcon({
    fill = '#EFF2F5',
    smart = false,
    width = 37,
    height = 29,
}: {
    fill?: string;
    smart?: boolean;
    width?: number;
    height?: number;
}) {
    return (
        <svg
            width={String(width)}
            height={String(height)}
            viewBox="0 0 37 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {smart ? (
                <>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.1096 0C1.83993 0 0 1.83993 0 4.10959H12.9167C12.9167 1.83993 11.0767 0 8.80707 0H4.1096ZM29.2466 4.10967H0V9.86311H35C35 6.68557 32.4241 4.10967 29.2466 4.10967Z"
                        fill={fill}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M35 11.9177H0V25C0 27.7614 2.23858 30 5 30H30C32.7614 30 35 27.7614 35 25V11.9177ZM19.4571 15.7809C19.4571 17.3708 18.4171 21.1001 15 21.1001V22.2358C16.3 22.2358 19.4571 22.5992 19.4571 27.6869H20.5714C20.5714 25.6428 20.72 22.2358 25.0286 22.2358V21.1001C23.3571 21.1001 20.5714 20.3235 20.5714 15.7809H19.4571ZM10 17.9043C12.025 17.9043 12.6413 15.724 12.6413 14.7946H13.3016C13.3016 17.4502 14.9524 17.9043 15.9429 17.9043V18.5682C13.3896 18.5682 13.3016 20.56 13.3016 21.755H12.6413C12.6413 18.7807 10.7704 18.5682 10 18.5682V17.9043ZM16.3206 12.822C16.3206 13.3112 16.0125 14.4586 15 14.4586V14.8081C15.3852 14.8081 16.3206 14.9199 16.3206 16.4854H16.6508C16.6508 15.8564 16.6948 14.8081 17.9714 14.8081V14.4586C17.4762 14.4586 16.6508 14.2197 16.6508 12.822H16.3206Z"
                        fill={fill}
                    />
                </>
            ) : (
                <>
                    <path
                        fill={fill}
                        d="M0.984375 9.21875H36.4688V8.03125C36.4688 4.8125 34.8125 3.1875 31.5625 3.1875H16.5625C15.5 3.1875 14.8594 2.9375 14.0781 2.26562L13.125 1.48438C12.0938 0.609375 11.2969 0.328125 9.75 0.328125H5.29688C2.5625 0.328125 0.984375 1.89062 0.984375 5.03125V9.21875ZM0.984375 24.0781C0.984375 27.3125 2.625 28.9219 5.89062 28.9219H32.0312C34.8281 28.9219 36.4688 27.2969 36.4688 24.0781V11.2656H0.984375V24.0781Z"
                    />
                </>
            )}
        </svg>
    );
}
