import React, { useEffect, useState } from 'react';
import styles from './Alert.module.scss';

type AlertProps = {
    title: string;
    description: string;
    id: string;
};

export function Alert({ title, description, id }: AlertProps): JSX.Element {
    const [isClosed, setIsClosed] = useState(
        window.localStorage.getItem(id) === 'closed' || false
    );

    useEffect(() => {
        if (!isClosed)
            document.getElementsByClassName(styles.description)[0].innerHTML =
                description ?? '';
    }, []);

    return isClosed ? (
        <></>
    ) : (
        <div className={styles.advise}>
            <div className={styles.container}>
                <p className={styles.title}>
                    <span className={styles.icon}>💬</span> {title}
                </p>
                <div className={styles.description} />
            </div>
            <div
                className={styles.close}
                onClick={() => {
                    setIsClosed(true);
                    window.localStorage.setItem(id, 'closed');
                }}
            />
        </div>
    );
}
