/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import Konva from 'konva';
import { Image as KImage } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';

const TrgImage = ({
    imageSrc,
    layerRef,
    brightness,
    contrast,
    isInverted = false,
    onClick,
    setScale,
}: {
    imageSrc: string;
    layerRef: Konva.Layer | null;
    brightness: number;
    contrast: number;
    isInverted?: boolean;
    onClick: (e: KonvaEventObject<MouseEvent>) => void;
    setScale: ({
        pointScale,
        fontScale,
    }: {
        pointScale: number;
        fontScale: number;
    }) => void;
}) => {
    const filters = [Konva.Filters.Brighten, Konva.Filters.Contrast];
    const [img, setImage] = useState<HTMLImageElement>();
    const imageRef = React.useRef<Konva.Image>(null);

    useEffect(() => {
        const image = new Image();
        image.addEventListener('load', () => {
            const parentHeight =
                imageRef.current?.parent?.toCanvas().height ?? 1;
            const parentWidth = imageRef.current?.parent?.toCanvas().width ?? 1;
            const scale = Math.min(
                parentWidth / image.width,
                parentHeight / image.height
            );
            setScale({
                pointScale: Math.sqrt(
                    (image.height * image.width) / (1700 * 1700)
                ),
                fontScale: image.height / 1700,
            });
            const size = {
                width: image.width * scale,
                height: image.height * scale,
            };

            layerRef?.scale({ x: scale, y: scale });
            layerRef?.position({
                x: parentWidth / 2 - size.width / 2,
                y: parentHeight / 2 - size.height / 2,
            });
            layerRef?.opacity(1);

            imageRef.current?.size(size);
            imageRef.current?.position({
                x: parentWidth / 2 - size.width / 2,
                y: parentHeight / 2 - size.height / 2,
            });

            setImage(image);
        });
        image.crossOrigin = '*';
        image.src = imageSrc;
    }, [layerRef]);

    useEffect(() => {
        imageRef?.current?.cache();
        imageRef?.current?.getLayer()?.batchDraw();
    }, [img]);

    if (isInverted) {
        filters.push(Konva.Filters.Invert);
    }

    return (
        <KImage
            ref={imageRef}
            image={img}
            filters={filters}
            brightness={brightness}
            contrast={contrast * 100}
            onContextMenu={(e) => {
                e.cancelBubble;
                e.evt.preventDefault();
                e.evt.stopPropagation();
            }}
            onClick={(e) => {
                onClick(e);
            }}
        />
    );
};

export default TrgImage;
