import React from 'react';
import ReactDOM from 'react-dom';
import style from './VideoModalWindow.module.scss';
import closeGrey from '@assets/images/close_grey.svg';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';

const VideoModalWindow = ({
    isShown,
    url,
    onClose,
    isLoading,
}: {
    isShown: boolean;
    url: string;
    onClose: () => void;
    isLoading: boolean;
}) => {
    return ReactDOM.createPortal(
        isShown && (
            <div className={style.modal__background} onClick={onClose}>
                <div>
                    {isLoading ? (
                        <CustomLoader size={100} />
                    ) : (
                        <iframe width="840" height="475" src={url}></iframe>
                    )}
                </div>
                <img
                    src={closeGrey}
                    alt={'close icon'}
                    width={36}
                    height={36}
                    className={style.modal__img}
                />
            </div>
        ),
        document.body
    );
};

export default VideoModalWindow;
