import { KonvaEventObject } from 'konva/lib/Node';
import React, { useCallback, useState, createContext } from 'react';

import { Layer } from 'react-konva';
import TrgImage from './TrgImage';

import { useAppSelector } from '@hooks/useAppSelector';
import { useAppDispatch } from '@hooks/useAppDispatch';

import {
    getBrightness,
    getContrast,
    getIsInverted,
} from '@store/feature/trg-state.slice';
import Konva from 'konva';

export const TrgContext = createContext({
    fontSize: 24,
    isInverted: false,
});

export function EditArea({
    imageSrc,
    children,
    className,
    onImageClick,
    rescale,
}: {
    className: string;
    imageSrc: string;
    onImageClick?: (p: { x: number; y: number }) => void;
    rescale: ({
        pointScale,
        fontScale,
    }: {
        pointScale: number;
        fontScale: number;
    }) => void;
    children?: JSX.Element | JSX.Element[] | null;
}): JSX.Element {
    const dispatch = useAppDispatch();
    const brightness = useAppSelector(getBrightness);
    const contrast = useAppSelector(getContrast);
    const isInverted = useAppSelector(getIsInverted);
    const layerRef = React.useRef<Konva.Layer>(null);

    const [fontSize, setFontSize] = useState(32);

    const ImageClicked = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            if (onImageClick) {
                const pos = layerRef.current?.getRelativePointerPosition();

                if (pos) onImageClick(pos);
            }
        },
        [onImageClick]
    );
    return (
        <>
            <Layer>
                <TrgImage
                    imageSrc={imageSrc}
                    layerRef={layerRef?.current}
                    brightness={brightness}
                    contrast={contrast}
                    onClick={ImageClicked}
                    isInverted={isInverted}
                    setScale={rescale}
                />
            </Layer>
            <TrgContext.Provider
                value={{
                    fontSize,
                    isInverted,
                }}
            >
                <Layer ref={layerRef} opacity={0}>
                    {children}
                </Layer>
            </TrgContext.Provider>
        </>
    );
}
