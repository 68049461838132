import React from 'react';
import styles from './TableRow.module.scss';
import { getMeasurementSymbol } from '@utils/trgReportUtils';

const TableRow = ({
    title,
    value,
    measurement,
    description,
    method,
    normal,
}: {
    title: string;
    value: number;
    measurement: string;
    description?: string;
    method: string;
    normal: [{ value: number }, { value: number }];
}) => {
    const showNormalValues = !(
        normal[0].value === 0 && normal[1].value === 1000
    );

    return (
        <tr className={styles.tableRow}>
            <td className={styles.tableRow__cell}>
                {measurement === 'DEGREE' && '∠'}
                {title}
            </td>
            <td className={styles.tableRow__cell}>
                <div className={styles.tableRow__valueContainer}>
                    <span className={styles.tableRow__value}>
                        {value ? value.toFixed(1) : ''}
                        {getMeasurementSymbol(measurement)}
                        {showNormalValues && (
                            <>
                                ({normal[0].value.toFixed(1)}
                                {method === 'BETWEEN' &&
                                    ` - ${normal[1].value.toFixed(1)}`}
                                {getMeasurementSymbol(measurement)})
                            </>
                        )}
                    </span>
                    <br />
                    <span>{description}</span>
                </div>
            </td>
        </tr>
    );
};

export default TableRow;
