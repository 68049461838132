/* eslint-disable react/react-in-jsx-scope */
import React, { useContext, useEffect, useState } from 'react';
import { ChartContext } from './Charts';
import { id } from '@utils/utils';

interface propType {
    x: number;
    y: number;
    min?: number;
    max?: number;
    value: number;
    label?: string | number;
    norm?: {
        method: string;
        status: string;
        value: [
            {
                value: number;
            },
            {
                value: number;
            }
        ];
    };
    options?: {
        width: number;
        height: number;
        tailHeight: number;
        margin?: [number, number, number, number];
        padding?: [number, number, number, number];
    };
}

export function ChartItem({
    x,
    y,
    min = 0,
    max = 100,
    label = '',
    value,
    norm,
    options = {
        width: 100,
        height: 50,
        tailHeight: 10,
        margin: [0, 0, 0, 0],
        padding: [0, 0, 0, 0],
    },
}: propType): JSX.Element {
    const gap = options.width / 8;
    const dx = (max - min) / 8;
    const { pushPoint } = useContext(ChartContext);
    const [line, setLine] = useState<
        {
            x1: number;
            x2: number;
            y1: number;
            y2: number;
        }[]
    >([]);

    useEffect(() => {
        const line = Array(9)
            .fill(1)
            .map((_, index) => {
                const trigger: number = index % 2;
                const y2 = trigger ? y + 5 : y + 10;

                return {
                    x1: x + index * gap,
                    x2: x + index * gap,
                    y1: y,
                    y2,
                };
            });
        setLine(line);
        const _value =
            value < min
                ? x
                : value > max
                ? x + ((max - min) / dx) * gap
                : x + ((value - min) / dx) * gap;

        pushPoint(_value, y);
    }, []);
    return (
        <g>
            <text
                x={x + options.width}
                y={y - 20}
                dominantBaseline="middle"
                textAnchor="end"
                fontSize="smaller"
            >
                {label}
            </text>
            {line.map((point, index) => {
                const trigger = index % 2;
                return (
                    <React.Fragment key={id()}>
                        <line
                            vectorEffect="non-scaling-stroke"
                            x1={point.x1}
                            x2={point.x2}
                            y1={point.y1}
                            y2={point.y2}
                            stroke="#000"
                        />
                        {!trigger && (
                            <text
                                x={(point.x1 + point.x2) / 2}
                                y={point.y2 + 10}
                                dominantBaseline="middle"
                                textAnchor="middle"
                                fontSize="smaller"
                            >
                                {index === 0 ? (
                                    <>
                                        {'<'} {min + dx * index}
                                    </>
                                ) : index === line.length - 1 ? (
                                    <>
                                        {min + dx * index}
                                        {' <'}
                                    </>
                                ) : (
                                    min + dx * index
                                )}
                            </text>
                        )}
                    </React.Fragment>
                );
            })}
            <line
                vectorEffect="non-scaling-stroke"
                x1={x}
                y1={y}
                x2={x + 8 * gap}
                y2={y}
                stroke="#000"
            />
            <circle
                cx={
                    value < min
                        ? x
                        : value > max
                        ? x + ((max - min) / dx) * gap
                        : x + ((value - min) / dx) * gap
                }
                cy={y}
                r={3}
                fill="blue"
            />{' '}
            {norm && norm?.method === 'EQUAL' && (
                <rect
                    y={y - 5}
                    x={x + ((norm.value[0].value - min) / dx) * gap - 5}
                    fillOpacity={0.7}
                    fill="#99FFA8"
                    height={10}
                    width={10}
                />
            )}
            {norm && norm?.method === 'BETWEEN' && (
                <rect
                    y={y - 5}
                    x={x + ((norm.value[0].value - min) / dx) * gap}
                    fillOpacity={0.7}
                    fill="#99FFA8"
                    height={10}
                    width={
                        ((norm.value[1].value - norm.value[0].value) / dx) * gap
                    }
                />
            )}
        </g>
    );
}
