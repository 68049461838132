import { TrgAPIs } from '@APIs/trg.apis';
import { ITrg } from '@interfaces/trg.interface';

export const confirmReport: (id?: string) => Promise<boolean> = async (
    id?: string
) => {
    if (!id) return false;

    try {
        await TrgAPIs.update(
            { is_verified: true, changed_at: Date.now() } as unknown as ITrg,
            id
        );

        return true;
    } catch (error) {
        return false;
    }
};
