import './MenuItem.scss';
import React, { useState } from 'react';

type MenuItemProps = {
    image: string;
    title: string;
    active: boolean;
};

const MenuItem = ({ title, image, active }: MenuItemProps): JSX.Element => {
    const [isMessageVisible, setIsMessageVisible] = useState(false);

    let isEnter = false;

    return (
        <div
            className="menu__item"
            onMouseEnter={() => {
                isEnter = true;
                setTimeout(() => {
                    if (isEnter) setIsMessageVisible(true);
                }, 500);
            }}
            onMouseLeave={() => {
                isEnter = false;
                setIsMessageVisible(false);
            }}
        >
            <div
                className="menu__item-row"
                key={title}
                style={{
                    pointerEvents: active ? 'auto' : 'none',
                    opacity: active ? 1.0 : 0.5,
                }}
            >
                <div className={'menu__item-image ' + 'menu__item-' + image} />
                <p className="menu__item-title">{title}</p>
            </div>
            {!active && isMessageVisible && (
                <div className={'menu__item-tip'}>Скоро</div>
            )}
        </div>
    );
};

export default MenuItem;
