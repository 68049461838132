import { ITrg } from '@interfaces/trg.interface';
import { TrgAPIs } from '@APIs/trg.apis';
import { changeTrgStatus } from '@feature/trg.slice';
import { toast } from 'react-toastify';
import { StatusCell } from '@components/presentationstable/common/StatusCell';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useAppDispatch } from '@hooks/useAppDispatch';

const TrgTableStatusColumn = (params: GridRenderCellParams<any, any, any>) => {
    const dispatch = useAppDispatch();
    const changeStatus = (newStatus: 'IN_PROCESS' | 'COMPLETED') => {
        const trg: ITrg = {
            ...params.row,
            state: newStatus,
            changed_at: Date.now(),
        };
        TrgAPIs.update(trg, trg.id)
            .then(() => {
                dispatch(
                    changeTrgStatus({
                        id: trg.id,
                        newState: newStatus,
                    })
                );
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
            });
    };
    return StatusCell(params.row.state, changeStatus);
};

export default TrgTableStatusColumn;
