import { RegisterOptions } from 'react-hook-form';
import React from 'react';

export const validators: Record<string, Partial<RegisterOptions>> = {
    EMAIL: {
        required: 'Это поле обязательно для заполнения',
        pattern: {
            value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
            message: 'Неверный адрес почты',
        },
    },
    PASSWORD: {
        required: 'Это поле обязательно для заполнения',
        minLength: 8,
    },
    NEWPASSWORD: {
        required: 'Это поле обязательно для заполнения',
        pattern: {
            value: /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
            message:
                'Пароль должен содержать: буквы латинского алфавита, минимум 1 большой символ, одну цифру и быть длиной от 8 символов',
        },
        maxLength: {
            value: 30,
            message: 'Пароль слишком длинный',
        },
    },
    PLAINTEXT: {
        pattern: {
            value: /^.*(?=.{2,})[а-яА-Я\-]*$/,
            message:
                'Поле может содержать дефис, заглавные и строчные русские буквы',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина 2',
        },
        maxLength: {
            value: 20,
            message: 'Максимальная длина 20',
        },
    },
    RESETCODE: {
        required: 'Это поле обязательно для заполнения',
        pattern: {
            value: /^[0-9]{6}$/,
            message: 'Код должен состоять из 6 цифр',
        },
    },
    NAME: {
        pattern: {
            value: /^.*(?=.{2,})[ёЁа-яА-Я\-]*$/,
            message:
                'Поле может содержать дефис, заглавные и строчные русские буквы',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина 2',
        },
        maxLength: {
            value: 20,
            message: 'Максимальная длина 20',
        },
        required: 'Это поле обязательно для заполнения',
    },

    ADDITIONAL: {
        minLength: {
            value: 2,
            message: 'Минимальная длина 2',
        },
        maxLength: {
            value: 30,
            message: 'Максимальная длина 20',
        },
    },
    BIRTHDAY: {
        required: 'Это поле обязательно для заполнения',
        validate: (date: Date) => {
            const currentDate = new Date();
            const monthAge =
                currentDate.getFullYear() - date.getFullYear() === 0
                    ? currentDate.getMonth() - date.getMonth()
                    : 12;
            return (
                (monthAge >= 3 &&
                    currentDate.getFullYear() - date.getFullYear() < 101 &&
                    date.getFullYear() <= currentDate.getFullYear()) ||
                'Возраст пациента должен составлять от 3 месяцев до 100 лет'
            );
        },
    },
};

export function removeNonDigits(e: React.FocusEvent<HTMLInputElement>): void {
    if (e.target && e.target.value) {
        (e.target as HTMLInputElement).value = e.target.value
            .replace(/([^0-9.])/g, '')
            .substring(0, 10);
    }
}

export function dateKeyHandler(e: React.KeyboardEvent<HTMLInputElement>): void {
    const event_target = e.target as HTMLInputElement;
    if (event_target) {
        const value = event_target.value;
        const cursorPosition = event_target.selectionStart;
        if (value) {
            let rawValue = value.replace(/[^0-9.]/g, '');
            if (e.key == '.') {
                e.preventDefault();
            }
            if (e.key === 'Backspace') {
                if (
                    cursorPosition !== null &&
                    rawValue[cursorPosition] === '.'
                ) {
                    rawValue = rawValue.substring(0, cursorPosition);
                }
            } else {
                if (rawValue.length == 2 || rawValue.length == 5) {
                    rawValue += '.';
                }
            }
            (e.target as HTMLInputElement).value = rawValue;
        }
    }
}
