import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { CellButtonComponent } from '@components/common/cell-button/cell-button';
import { ROUTES } from '@constants/routes';

const TrgTableActionsColumn = ({
    params,
    onClick,
}: {
    params: GridRenderCellParams;
    onClick: () => void;
}) => {
    return (
        <CellButtonComponent
            to={ROUTES.TRG.$(params?.row.id).EDIT.END}
            onClick={onClick}
        >
            Просмотр
        </CellButtonComponent>
    );
};

export default TrgTableActionsColumn;
