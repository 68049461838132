import React, { useRef, useState } from 'react';
import Tutorial from '@components/common/Tutorial/Tutorial';
import { ConfirmModal } from '../confirm-modal';
import { useParams } from 'react-router';
import { requestTRG } from './lib';
import Button from '@components/common/NEWDESIGN/Button/Button';

export default function TrgEditToolBar({
    onReport,
    canOpenReport,
}: {
    onReport: () => void;
    canOpenReport: boolean;
}): JSX.Element {
    const trgEditToolBarRef = useRef<HTMLDivElement>(null);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { trgId } = useParams<{ trgId: string }>();

    const handleReportClick = async () => {
        setIsLoading(true);
        const isVerified = await requestTRG(trgId);
        setIsLoading(false);

        if (!isVerified) {
            setIsConfirmModalOpen(true);
            return;
        }

        canOpenReport && onReport();
    };

    return (
        <>
            <div ref={trgEditToolBarRef} className="add-trg__toolbar-wrapper">
                <div className="store__free-space" />
                <div className="add-trg__report-btnContainer">
                    <Tutorial tutorial="trg" />
                    <Button
                        text="Отчет"
                        isActive={canOpenReport}
                        onClick={handleReportClick}
                        size="SMALL"
                        padding="9px 30px"
                        hoverTextForInactive="Расставлены не все точки"
                    />
                </div>
            </div>
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                onClose={() => setIsConfirmModalOpen(false)}
                onReport={onReport}
            />
        </>
    );
}
