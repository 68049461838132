import React from 'react';
import './table__avatar-cell.scss';

export function TableAvatarCellElem({
    id,
    avatar,
    name,
}: {
    id?: string;
    avatar?: string;
    name?: string;
}): JSX.Element {
    const noAvatarSymbol = name === 'Неизвестный' ? '?' : name?.charAt(0);

    return (
        <>
            {avatar && id && (
                <div
                    className="table__avatar-image table__avatar-image_loaded"
                    style={{
                        backgroundImage: `url(${avatar})`,
                    }}
                />
            )}
            {(!avatar || !id) && (
                <div className="table__avatar-image">
                    <p className="table__avatar-placeholder">
                        {noAvatarSymbol}
                    </p>
                </div>
            )}
        </>
    );
}
