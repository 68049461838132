import React from 'react';
import './table__patient-btn.scss';

export function TablePatientBtn({
    children,
    onClick,
}: {
    children: JSX.Element;
    onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}): JSX.Element {
    return (
        <div onClick={onClick} className="table__patient-btn">
            {children}
        </div>
    );
}
