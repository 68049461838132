import React from 'react';
import CustomSelect from '../custom-select/Custom-Select';
import { ITrgMethod } from '@interfaces/trg-method.interface';

export default function TrgMethods({
    selectedMethodTitle,
    isActive,
    isOpen,
    setIsOpen,
    methods,
    setTrgMethod,
}: {
    selectedMethodTitle?: string;
    isActive: boolean;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    methods: ITrgMethod[];
    setTrgMethod: (methodId: string) => void;
}): JSX.Element {
    const onSelect = (methodId: string) => {
        const selectedMethod = methods.find((method) => method.id === methodId);
        if (selectedMethod) {
            setTrgMethod(selectedMethod.id);
        }
    };

    return (
        <CustomSelect
            title="Методы расчета"
            onSelect={onSelect}
            selectedItemTitle={selectedMethodTitle}
            isActive={isActive}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            items={methods.map((method) => ({
                id: method.id,
                label: method.title,
            }))}
        />
    );
}
