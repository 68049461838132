import React from 'react';
import './LoadingIndicator.scss';
import { classes } from '@utils/classNames';

type LoadingIndicatorProps = { isLoading: boolean; withPadding?: boolean };

const LoadingIndicator = ({
    isLoading,
    withPadding = true,
}: LoadingIndicatorProps): JSX.Element => {
    return (
        <div
            className={classes('inline_loading_root', {
                ['inline_loading_root_withoutPadding']: !withPadding,
            })}
        >
            <div className={isLoading ? 'test' : 'test paused'} />
        </div>
    );
};

export default LoadingIndicator;
