import React from 'react';
import styles from './LoadingSpinner.module.scss';
import MoonLoader from 'react-spinners/MoonLoader';

const LoadingSpinner = ({
    width = 16,
    height = 16,
    stroke = '#FAFBFF',
}: {
    width?: number;
    height?: number;
    stroke?: string;
}): JSX.Element => {
    return (
        <div className={styles.spinner_container}>
            <svg
                width={width}
                height={height}
                viewBox="0 0 26 26"
                style={{ width, height }}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.spinner}
            >
                <path
                    d="M1 13C1 19.6274 6.37258 25 13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1"
                    stroke={stroke}
                    strokeWidth="2"
                    strokeMiterlimit="1.8118"
                    strokeLinecap="round"
                />
            </svg>
        </div>
    );
};

export default LoadingSpinner;

export const CustomLoader = ({
    size = 44,
    color = 'var(--blue)',
}: {
    size?: number;
    color?: string;
}): JSX.Element => (
    <div className={styles.spinner_container}>
        <MoonLoader color={color} size={size} />
    </div>
);
