import React from 'react';
import './search-component.scss';

export function SearchComponent({
    onChange,
}: {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element {
    return (
        <div className="search">
            <div className="search-image" />
            <input
                placeholder="Поиск"
                onChange={onChange}
                className="search-input"
            />
        </div>
    );
}
