import React, { FC, useState } from 'react';
import { useParams } from 'react-router';

import { Modal } from '@components/UI/Modal';
import Button from '@components/common/button/Button';
import { Checkbox } from '@components/checkbox/Checkbox';
import { confirmReport } from './lib';

import styles from './ConfirmModal.module.scss';
import { on } from 'events';

interface IConfirmModal {
    isOpen: boolean;
    onClose: VoidFunction;
    onReport: VoidFunction;
}

export const ConfirmModal: FC<IConfirmModal> = ({
    isOpen,
    onClose,
    onReport,
}) => {
    const { trgId } = useParams<{ trgId: string }>();

    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleReportClick = async () => {
        setIsLoading(true);
        const res = await confirmReport(trgId);
        setIsLoading(false);

        if (!res) {
            onClose();
            return;
        }

        onReport();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Подтвердите действие">
            <p className={styles.text}>
                Вы использовали для расчета умную разметку. Подтвердите, что Вы
                проверили расстановку точек
            </p>
            <Checkbox
                label="Все точки на цефалометрии расставлены правильно"
                isChecked={isChecked}
                isDisabled={false}
                name="checkbox"
                onChange={() => setIsChecked((p) => !p)}
                labelStyle={{ fontSize: '16px' }}
            />
            <div className={styles.buttonsContainer}>
                <Button
                    title="К отчету"
                    extraStyle={{
                        padding: '8px 12px',
                        minWidth: '81px',
                        fontSize: '15px',
                    }}
                    onClick={handleReportClick}
                    disabled={!isChecked}
                    isLoading={isLoading}
                />
            </div>
        </Modal>
    );
};
