import React from 'react';
import styles from './CustomToastError.module.scss';

const CustomToastError = ({
    text,
    buttonText,
    onClick,
}: {
    text: string;
    buttonText: string;
    onClick: () => void;
}) => {
    return (
        <div className={styles.error}>
            <span className={styles.error__text}>{text}</span>
            <div className={styles.error__buttonContainer}>
                <span onClick={onClick} className={styles.error__button}>
                    {buttonText}
                </span>
            </div>
        </div>
    );
};

export default CustomToastError;
