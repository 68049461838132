import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TrgAPIs } from '@APIs/trg.apis';
import { toast } from 'react-toastify';
import TableEditInput from '@components/common/tableEditInput/TableEditInput';

interface extraGridRenderCellParams {
    updatePresentationInState: (
        id: string,
        title: string,
        updated_at: number
    ) => void;
}

const TrgTableTitleColumn = (
    params: GridRenderCellParams & extraGridRenderCellParams
) => {
    const title = params.row?.title;

    return (
        <div className="client-trg__table-title-input">
            <TableEditInput
                value={title}
                onAcceptIconClick={(title) => {
                    TrgAPIs.update(
                        { ...params.row, title, changed_at: Date.now() },
                        params.row.id
                    )
                        .then(({ data }) => {
                            params.updatePresentationInState(
                                data.id,
                                data.title,
                                data.updated_at
                            );
                        })
                        .catch((error) => {
                            if (error.response) {
                                toast.error(error.response.data.message);
                            }
                        });
                }}
            />
        </div>
    );
};

export default TrgTableTitleColumn;
