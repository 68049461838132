/* eslint-disable react/react-in-jsx-scope */

import React, { createContext, useCallback, useState } from 'react';
import { ChartItem } from './ChartsItem';

export const ChartContext = createContext<{
    pushPoint: (x: number, y: number) => void;
}>({
    pushPoint: (x: number, y: number) => {
        /* empty */
    },
});

export function Charts({
    values,
    width,
    height,
    margin = [0, 0, 0, 0],
    viewBox,
    chartOptions = {
        width: 100,
        height: 50,
        margin: [0, 0, 0, 0],
        padding: [0, 0, 0, 0],
    },
}: {
    values: Array<
        [
            number,
            number,
            number,
            {
                method: string;
                status: string;
                value: [
                    {
                        value: number;
                    },
                    {
                        value: number;
                    }
                ];
            },
            number | string
        ]
    >;
    viewBox: string;
    width: number;
    height: number;
    margin?: [number, number, number, number];
    padding?: [number, number, number, number];
    chartOptions?: {
        width: number;
        height: number;
        margin?: [number, number, number, number];
        padding?: [number, number, number, number];
    };
}): JSX.Element {
    const [position, setPosition] = useState({
        x: 0,
        y: 0,
        w: width,
        h: height,
        margin: [0, 0, 0, 0],
        padding: [0, 0, 0, 0],
    });

    const [line, setVLine] = useState('');

    const [points, setChartPoints] = useState<{
        [key: string]: { x: number; y: number };
    }>({});

    const pushPoint = useCallback((x: number, y: number) => {
        setChartPoints((curr) => {
            curr[`${x},${y}`] = { x, y };
            setVLine(Object.keys(curr).join(' '));
            return curr;
        });
    }, []);

    return (
        <svg
            // width={width}
            height={height}
            width="100%"
            // height="100%"
            viewBox={viewBox}
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <linearGradient id="grad" x1={0} y1={0} y2={0} x2={width}>
                    <stop offset="0" stopColor="red" />
                    <stop offset={width / 2} stopColor="black" />
                    <stop offset={width} stopColor="red" />
                </linearGradient>
            </defs>
            <ChartContext.Provider value={{ pushPoint }}>
                {values.map(([min, max, value, norm, title], index) => (
                    <ChartItem
                        key={index}
                        min={min}
                        max={max}
                        norm={norm}
                        value={value}
                        label={title}
                        y={position.y + index * chartOptions.height}
                        x={position.x}
                        options={{ ...chartOptions, tailHeight: 10 }}
                    />
                ))}
                <polyline
                    points={line}
                    fill="none"
                    stroke={'black'}
                    strokeWidth={2}
                />
            </ChartContext.Provider>{' '}
        </svg>
    );
}
