import React from 'react';
import styles from './Spinner.module.scss';
import spinner from '../../../assets/images/spinner.svg';

export const Spinner = (): JSX.Element => {
    return (
        <div className={styles.Container}>
            <img src={spinner} className={styles.Spinner} />
        </div>
    );
};
