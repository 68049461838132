import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './Checkbox.module.scss';

type CheckboxProps = {
    isChecked: boolean;
    isDisabled: boolean;
    onChange: () => void;
    label: string;
    name: string;
    register?: UseFormRegisterReturn;
    labelStyle?: React.CSSProperties;
};

export const Checkbox = ({
    isChecked,
    isDisabled,
    onChange,
    label,
    name,
    register,
    labelStyle,
}: CheckboxProps): JSX.Element => {
    return (
        <>
            <input
                className={styles.Hidden}
                type="checkbox"
                id={name}
                disabled={isDisabled}
                checked={isChecked}
                onChange={onChange}
                {...register}
            />
            <label className={styles.Label} style={labelStyle} htmlFor={name}>
                {label}
            </label>
        </>
    );
};
