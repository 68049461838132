import React from 'react';
import styles from './LoadingPage.module.scss';
import { CustomLoader } from '../loadingspinner/LoadingSpinner';

const LoadingPage = (): JSX.Element => {
    return (
        <div className={styles.container}>
            <CustomLoader />
        </div>
    );
};

export default LoadingPage;
