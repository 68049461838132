import React from 'react';
import styles from './MenuUser.module.scss';
import { classes } from '@utils/classNames';

const MenuUserItem = ({
    name,
    surname,
    image,
    position,
    purchase,
}: {
    name: string;
    surname: string;
    image: string;
    position?: string;
    purchase: boolean;
}): JSX.Element => (
    <div className={styles.container}>
        <div className={styles.container__itemProfile}>
            {image ? (
                <img
                    className={
                        purchase
                            ? styles.container__imageActive
                            : styles.container__image
                    }
                    src={image}
                    alt={'avatar'}
                />
            ) : (
                <div
                    className={
                        purchase
                            ? 'user_list_table_row_image_active user_list_table_row_image_large'
                            : 'user_list_table_row_image user_list_table_row_image_large'
                    }
                >
                    <p className="user-list-table-row-image-placeholder">
                        {name.charAt(0)}
                    </p>
                </div>
            )}
            <div className={styles.container__nameContainer}>
                <p
                    className={classes(styles.container__titleContainer, {
                        [styles.container__titleContainer_small]:
                            surname.length > 9 || name.length > 10,
                    })}
                >
                    {`${surname} ${name}`}
                </p>
                <p className={styles.container__subtitleContainer}>
                    {position ?? ''}
                </p>
            </div>
        </div>
    </div>
);

export default MenuUserItem;
