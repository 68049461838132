import React, { useState } from 'react';
import { Circle, Text } from 'react-konva';

type PropsType = {
    radius: number;
    x: number;
    y: number;
    title: string;
    scale?: number;
};

export function TrgReportPoint(props: PropsType): JSX.Element {
    const [pos, setPos] = useState({
        x: props.x,
        y: props.y,
    });

    return (
        <>
            <Circle
                fill={['m0', 'm1'].includes(props.title) ? '#F0F' : '#F00'}
                x={pos.x}
                y={pos.y}
                radius={props.radius + 3}
                listening={false}
                stroke="#FFF"
                strokeWidth={1}
                scaleX={props.scale ?? 1}
                scaleY={props.scale ?? 1}
            />
            <Text
                x={pos.x + props.radius + 2}
                y={pos.y + props.radius + 2}
                text={props.title}
                listening={false}
                fill="#FFF"
                fontSize={14}
            />
        </>
    );
}
