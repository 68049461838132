import React, { useEffect, useState } from 'react';
import './edit-tools.scss';

import Editor from '../editor/Editor';
import { TrgToolEnum } from '@interfaces/tools.interface';
import { useAppSelector } from '@hooks/useAppSelector';
import { useAppDispatch } from '@hooks/useAppDispatch';
import {
    selectTrgEditTool,
    selectTrgEnabledEditTool,
    setTrgEditTool,
} from '@store/feature/tools.slice';
import TrgToolButton from './TrgToolButton';
import TrgPoint from '@images/trg_point.svg';
import TrgMove from '@images/trg_move.svg';
import TrgMeasure from '@images/trg_measure.svg';
import TrgSettings from '@images/trg_settings.svg';
import TrgZoom from '@images/trg_zoom.svg';
import TrgOut from '@images/trg_out.svg';

export default function EditTools({
    className,
}: {
    className: string;
}): JSX.Element {
    const dispatch = useAppDispatch();
    const selectedTool = useAppSelector(selectTrgEditTool);
    const enabledTools = useAppSelector(selectTrgEnabledEditTool);
    const [selected, setSelected] = useState<TrgToolEnum | null>();

    const setSelectedTool = (selectedTool: TrgToolEnum) => {
        dispatch(setTrgEditTool(selectedTool));
    };

    function handleButtonClick(tool: TrgToolEnum) {
        if (selected === tool) {
            setSelectedTool(TrgToolEnum.MOVE);
        } else {
            setSelectedTool(tool);
        }
    }

    // Not show zoom and out active cause blink
    useEffect(() => {
        if (
            selectedTool == TrgToolEnum.ZOOM ||
            selectedTool == TrgToolEnum.OUT
        ) {
        } else {
            setSelected(selectedTool);
        }
    }, [selectedTool]);

    return (
        <div className={className}>
            <TrgToolButton
                icon={TrgMeasure}
                tip={'Установить 10мм'}
                isEnabled={enabledTools?.includes(TrgToolEnum.MEASURE) ?? false}
                isActive={selected == TrgToolEnum.MEASURE}
                onClick={() => {
                    handleButtonClick(TrgToolEnum.MEASURE);
                }}
                forceMessage={!enabledTools?.includes(TrgToolEnum.POINT)}
            />
            <TrgToolButton
                icon={TrgPoint}
                tip={'Поставить точку'}
                isEnabled={enabledTools?.includes(TrgToolEnum.POINT) ?? false}
                isActive={selected == TrgToolEnum.POINT}
                onClick={() => {
                    handleButtonClick(TrgToolEnum.POINT);
                }}
            />
            <TrgToolButton
                icon={TrgMove}
                tip={'Переместить'}
                isEnabled={enabledTools?.includes(TrgToolEnum.MOVE) ?? false}
                isActive={selected == TrgToolEnum.MOVE}
                onClick={() => {
                    setSelectedTool(TrgToolEnum.MOVE);
                }}
            />
            <div className="edit-tools">
                {selected === TrgToolEnum.EDIT && <Editor />}
            </div>
            <TrgToolButton
                icon={TrgSettings}
                tip={'Настройки'}
                isEnabled={enabledTools?.includes(TrgToolEnum.EDIT) ?? false}
                isActive={selected == TrgToolEnum.EDIT}
                onClick={() => {
                    handleButtonClick(TrgToolEnum.EDIT);
                }}
            />
            <div className="space-tool" />
            <TrgToolButton
                icon={TrgZoom}
                tip={'Приблизить'}
                isEnabled={enabledTools?.includes(TrgToolEnum.ZOOM) ?? false}
                isActive={selected == TrgToolEnum.ZOOM}
                onClick={() => {
                    setSelectedTool(TrgToolEnum.ZOOM);
                }}
            />
            <TrgToolButton
                icon={TrgOut}
                tip={'Отдалить'}
                isEnabled={enabledTools?.includes(TrgToolEnum.OUT) ?? false}
                isActive={selected == TrgToolEnum.OUT}
                onClick={() => {
                    setSelectedTool(TrgToolEnum.OUT);
                }}
            />
        </div>
    );
}
