import React, { useState, useEffect, useRef } from 'react';
import styles from './InputField.module.scss';

import shown from '@images/eye-opened.svg';
import hidden from '@images/eye-closed.svg';
import required from '@images/required.svg';
import {
    Control,
    Controller,
    FieldValues,
    UseFormRegisterReturn,
} from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { dateKeyHandler, removeNonDigits } from './helpers/validation';
import { classes } from '@utils/classNames';

type InputProps = {
    label: string;
    placeholder: string;
    fieldName: string;
    type: 'PASSWORD' | 'EMAIL' | 'PLAINTEXT' | 'DATE';
    isDisabled?: boolean;
    isRequired?: boolean;
    register?: UseFormRegisterReturn<string>;
    helpText?: string;
    helpUrl?: string;
    errorText?: string;
    control?: unknown;
    className?: string;
    additionalText?: string;
    timerTime?: number;
    onAdditionalTextClick?: () => void;
};

const InputField = ({
    label,
    placeholder,
    fieldName,
    isDisabled = false,
    isRequired = true,
    register,
    type,
    helpText,
    helpUrl,
    errorText,
    control,
    className,
    additionalText,
    timerTime = 0,
    onAdditionalTextClick,
}: InputProps): JSX.Element => {
    const [isPasswordShown, toggleShow] = useState(false);
    const [seconds, setSeconds] = useState(timerTime);
    const [isAdditionalTextDisabled, setIsAdditionalTextDisabled] = useState(
        timerTime > 0
    );

    const timerRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (seconds > 0) {
            timerRef.current = setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            setIsAdditionalTextDisabled(false);
        }
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [seconds]);

    const handleAdditionalTextClick = () => {
        if (onAdditionalTextClick) {
            onAdditionalTextClick();
        }
        setSeconds(timerTime);
        setIsAdditionalTextDisabled(true);
    };

    return (
        <div className={classes(styles.Wrapper, !!className ? className : '')}>
            <div className={styles.Labels}>
                <label className={styles.Label} htmlFor={fieldName}>
                    {label}
                    {isRequired && (
                        <img
                            className={styles.Required}
                            src={required}
                            alt={'isRequired'}
                        />
                    )}
                </label>
                {additionalText && (
                    <div
                        className={classes(styles.AdditionalText, {
                            [styles.AdditionalText_disabled]:
                                isAdditionalTextDisabled,
                        })}
                        onClick={
                            !isAdditionalTextDisabled
                                ? handleAdditionalTextClick
                                : undefined
                        }
                    >
                        {additionalText}
                        {isAdditionalTextDisabled && (
                            <span> ({seconds} сек.)</span>
                        )}
                    </div>
                )}
            </div>
            {type === 'DATE' ? (
                <Controller
                    control={control as Control<FieldValues, unknown>}
                    name={fieldName}
                    render={({ field }) => (
                        <ReactDatePicker
                            className={styles.Input}
                            placeholderText="дд/мм/гггг"
                            onChange={(e) => field.onChange(e)}
                            selected={field.value}
                            locale="ru"
                            dateFormat="dd.MM.yyyy"
                            onKeyDown={dateKeyHandler}
                            onChangeRaw={removeNonDigits}
                            customInput={
                                <input
                                    className={styles.Input}
                                    type="text"
                                    title="dd.MM.yyyy"
                                />
                            }
                        />
                    )}
                />
            ) : (
                <input
                    className={classes(styles.Input, {
                        [styles.Input__password]: type === 'PASSWORD',
                    })}
                    type={
                        type === 'PASSWORD' && !isPasswordShown
                            ? 'password'
                            : 'text'
                    }
                    placeholder={placeholder}
                    name={fieldName}
                    disabled={isDisabled}
                    {...register}
                />
            )}
            <div className={styles.ButtonWrapper}>
                {type === 'PASSWORD' && (
                    <img
                        src={isPasswordShown ? shown : hidden}
                        className={styles.ShowButton}
                        onClick={() => toggleShow(!isPasswordShown)}
                        alt={'eye'}
                    />
                )}
            </div>
            {helpText && (
                <a href={helpUrl} className={styles.HelpText}>
                    {helpText}
                </a>
            )}
            {!!errorText && <div className={styles.Warning}>{errorText}</div>}
        </div>
    );
};

export default InputField;
