import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useAuth } from '@contexts/AuthContext';
import { ROUTES } from '@constants/routes';
import LoadingPage from './common/loading/LoadingPage';

export type PrivateRouteProps = RouteProps;

const PrivateRoute = ({ ...routeProps }: PrivateRouteProps): JSX.Element => {
    const { isAuthenticated, profile, isLoadingProfile } = useAuth();
    if (!isAuthenticated) {
        return (
            <Route
                render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: ROUTES.LOGIN.END,
                            state: { from: location },
                        }}
                    />
                )}
            />
        );
    } else if (isLoadingProfile) {
        return <Route component={LoadingPage} />;
    } else if (isAuthenticated && profile.isEmailValid) {
        return <Route {...routeProps} />;
    } else {
        return (
            <Route
                render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: ROUTES.VERIFY.END,
                            state: { from: location },
                        }}
                    />
                )}
            />
        );
    }
};

export default PrivateRoute;
