import React, { useEffect, useState } from 'react';
import style from './Tutorial.module.scss';
import question from '@assets/images/question.svg';
import VideoModalWindow from '@components/common/VideoModalWindow/VideoModalWindow';
import { TutorialsAPIs } from '@APIs/tutorials.apis';

const Tutorial = ({
    tutorial,
}: {
    tutorial: 'trg' | 'presentation' | 'collection';
}) => {
    const [isShown, setIsShown] = useState(false);
    const [url, setUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        TutorialsAPIs.getTutorial(tutorial)
            .then(({ data }) => {
                setUrl(data.link);
            })
            .finally(() => setIsLoading(false));
    }, []);

    return (
        <div>
            <div
                className={style.tutorial__iconContainer}
                onClick={() => setIsShown(true)}
            >
                <img src={question} alt="question-icon" />
            </div>
            <VideoModalWindow
                isShown={isShown}
                url={url}
                onClose={() => {
                    setIsShown(false);
                }}
                isLoading={isLoading}
            />
        </div>
    );
};

export default Tutorial;
