import React, { useEffect } from 'react';
import { Switch, Link } from 'react-router-dom';

import style from './Menu.module.scss';
import MenuItem from './menuitem/MenuItem';
import { MenuItemsMemo } from './menuItems/MenuItems';
import logo from '@images/logo.svg';
import { ReactComponent as TelegramIcon } from '@images/telegramIcon.svg';
import { ReactComponent as InstagramIcon } from '@images/instagramIcon.svg';
import { ReactComponent as VibeLabIcon } from '@images/companyLogo.svg';
import { Redirect, Route } from 'react-router';
import LogoutLink from '../logout/LogoutLink';
import HomePage from '@components/home/HomePage';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import format from 'date-fns/format';
import { useAuth } from '@contexts/AuthContext';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { asyncLoadPatients } from '@store/feature/patients.slice';
import { asyncLoadPresentations } from '@store/feature/presentations.slice';
import { ROUTES } from '@constants/routes';
import { asyncLoadTrgList } from '@store/feature/trg.slice';

import PresentationsTablePage from '@components/presentationstable/PresentationsTablePage';

import TrgReport from '@components/TrgReport/TrgReport';
import TrgPage from '@pages/Trg/Table/TrgTable';
import TrgEdit from '@pages/TrgEdit/TrgEdit';
import PhotoMatching from '@pages/PhotoMatching/PhotoMatching';

import PatientsPage from '@components/patient/patients/PatientsPage';
import PatientInfoPage from '@components/patient/patientinfo/PatientInfoPage';
import AddPatientPage from '@components/patient/patientinfo/addpatient/AddPatientPage';
import ProfilePage from '@components/profile/ProfilePage';
import NotificationPage from '@components/common/PaymentNotification/NotificationPage';

import PaymentPage from '@components/payment/PaymentPage';
import SupportPage from '@components/support/SupportPage';
import { useReleaseNotes } from './hooks';
import { WhatNewsModal } from '@components/whatNewsModal';

class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date: Date) {
        return format(date, 'd MMM yyyy', { locale: this.locale });
    }
}

export const Menu = (): JSX.Element => {
    const { profile, profilePicture } = useAuth();
    const dispatch = useAppDispatch();
    const { isWhatsNewModalOpen, setIsWhatsNewModalOpen, releaseNotesData } =
        useReleaseNotes();

    useEffect(() => {
        dispatch(asyncLoadPatients());
        dispatch(asyncLoadPresentations());
        dispatch(asyncLoadTrgList());
    }, []);

    return (
        <>
            <div>
                <div className={style.menu__rootContainer}>
                    <div className={style.menu__container}>
                        <div className={style.menu__itemContainer}>
                            <div className={style.menu__logoContainer}>
                                <Link to={ROUTES.PATIENTS.END}>
                                    <img
                                        alt="menu logo"
                                        className={style.menu__logo}
                                        src={logo}
                                    />
                                </Link>
                            </div>
                            <MenuItemsMemo
                                profile={profile}
                                profilePicture={profilePicture}
                            />
                        </div>
                        <a
                            target="_blank"
                            href="https://t.me/dilab_support"
                            rel="noreferrer noopener"
                        >
                            <MenuItem
                                image={'support'}
                                title={'Поддержка'}
                                active={true}
                            />
                        </a>

                        <div style={{ width: '100%', height: '100px' }} />
                        <LogoutLink />
                        <div className={style.menu__companyLogoContainer}>
                            <a
                                target="_blank"
                                href="https://vibelab.ru"
                                rel="noreferrer noopener"
                            >
                                <VibeLabIcon />
                            </a>
                            <a
                                target="_blank"
                                href="https://t.me/dilab_app"
                                rel="noreferrer noopener"
                            >
                                <TelegramIcon />
                            </a>
                            <a
                                target="_blank"
                                href="https://www.instagram.com/dilab.app"
                                rel="noreferrer noopener"
                            >
                                <InstagramIcon />
                            </a>
                        </div>
                    </div>

                    <div className={style.page__container}>
                        <MuiPickersUtilsProvider
                            utils={LocalizedUtils}
                            locale={ruLocale}
                        >
                            <Switch>
                                <Redirect
                                    exact
                                    path={ROUTES.ROOT.END}
                                    to={ROUTES.PATIENTS.END}
                                />
                                <Route exact path={ROUTES.PATIENTS.END}>
                                    <PatientsPage />
                                </Route>
                                <Route exact path={ROUTES.PATIENTS.ADD.END}>
                                    <AddPatientPage />
                                </Route>
                                <Route
                                    path={
                                        ROUTES.PATIENTS.$(':patientId')
                                            .PHOTOS.$(':parentId')
                                            .FILL.$(':collectionId').END
                                    }
                                >
                                    <PhotoMatching />
                                </Route>
                                {process.env?.APP_MODE &&
                                    process.env?.APP_MODE === 'IN_TEST' && (
                                        <Route
                                            path={
                                                ROUTES.PATIENTS
                                                    .PHOTO_COLLECTIONS.END
                                            }
                                            // eslint-disable-next-line react/no-children-prop
                                            children={() => {
                                                return import(
                                                    '@pages/PhotoMatching/PhotoMatching'
                                                ) as unknown as React.ReactNode;
                                            }}
                                        />
                                    )}
                                <Route
                                    path={ROUTES.PATIENTS.$(':patientId').END}
                                >
                                    <PatientInfoPage />
                                </Route>
                                <Route exact path={ROUTES.PRESENTATIONS.END}>
                                    <PresentationsTablePage />
                                </Route>
                                <Route path={ROUTES.PAYMENT.END}>
                                    <PaymentPage />
                                </Route>
                                <Route
                                    path={[
                                        ROUTES.PRESENTATIONS.$(
                                            ':presentationId'
                                        ).END,
                                        ROUTES.PRESENTATIONS.END,
                                    ]}
                                >
                                    <HomePage />
                                </Route>
                                <Route exact path={[ROUTES.TRG.END]}>
                                    <TrgPage />
                                </Route>
                                <Route exact path={[ROUTES.SUPPORT.END]}>
                                    <SupportPage />
                                </Route>
                                <Route
                                    exact
                                    path={[ROUTES.TRG.$(':trgId').EDIT.END]}
                                >
                                    <TrgEdit />
                                </Route>
                                <Route
                                    exact
                                    path={[
                                        ROUTES.TRG.$(':trgReportId').REPORT.END,
                                    ]}
                                >
                                    <TrgReport />
                                </Route>
                                <Route exact path={ROUTES.PROFILE.END}>
                                    <ProfilePage />
                                </Route>
                                <Route exact path={'/payment'}>
                                    <NotificationPage />
                                </Route>
                            </Switch>
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
            </div>
            <WhatNewsModal
                isOpen={isWhatsNewModalOpen}
                onClose={() => setIsWhatsNewModalOpen(false)}
                releaseNotesData={releaseNotesData}
            />
        </>
    );
};
