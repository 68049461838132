import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import './common/react-datepicker.scss';
import { UserPhotoUpload } from '@components/patient/patientinfo/addpatient/userPhotoUpload/UserPhotoUpload';
import PatientSexField from './common/PatientSexField';
import styles from './PatientEditInfoPage.module.scss';
import saveButtonStyles from './common/SaveButton.module.scss';
import { Spinner } from '@components/common/spinner/Spinner';
// import { Checkbox } from '@components/checkbox/Checkbox';
import { updatePatient, uploadPatientAvatar } from '@utils/patients';
import { Sex } from '@models/sex.enum';
import { useAppSelector } from '@hooks/useAppSelector';
import { asyncLoadPatients } from '@store/feature/patients.slice';
import { selectSelectedPatient } from '@store/feature/patient.slice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import InputField from '@components/inputfield/InputField';
import { validators } from '@components/inputfield/helpers/validation';
import { toast } from 'react-toastify';

registerLocale('ru', ru);
setDefaultLocale('ru');

type EditPatientFormDataType = {
    email: string;
    surname: string;
    name: string;
    middleName: string;
    birthday: Date;
    'patient-notify'?: boolean;
};

export const PatientEditInfoPage = (): JSX.Element => {
    const [sex, setSex] = useState(Sex.M);
    const [isLoading, setIsLoading] = useState(false);
    const selectedPatient = useAppSelector(selectSelectedPatient);
    const dispatch = useAppDispatch();
    const [selectedFile, setSelectedFile] = useState<File>();
    const [selectedFileUrl, setSelectedFileUrl] = useState('');
    const [isEmailUsed, setEmailUsed] = useState(false);
    // const [shouldNotify, setNotify] = useState(false);
    const patientSex = selectedPatient?.sex;
    useEffect(() => {
        if (patientSex) {
            setSex(patientSex as Sex);
        }
    }, [patientSex]);
    const [isSaveActive, setSaveActive] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        reset,
    } = useForm<EditPatientFormDataType>();

    useEffect(() => {
        setSelectedFileUrl(selectedPatient?.avatar ?? '');
    }, [selectedPatient]);

    useEffect(() => {
        reset({
            email: selectedPatient?.email,
            name: selectedPatient?.name,
            surname: selectedPatient?.surname,
            middleName: selectedPatient?.middleName,
        });
        if (selectedPatient?.dateOfBirth) {
            setValue('birthday', new Date(selectedPatient.dateOfBirth));
        }
        if (selectedPatient?.email) setEmailUsed(true);
    }, [setValue, selectedPatient, reset]);

    const updatePatientInfo = async (
        formData: EditPatientFormDataType
    ): Promise<void> => {
        if (selectedPatient) {
            setIsLoading(true);
            try {
                if (selectedFile) {
                    await uploadPatientAvatar(selectedPatient.id, selectedFile);
                }
                const res = await updatePatient({
                    ...selectedPatient,
                    ...formData,
                    sex,
                    dateOfBirth:
                        new Date(formData.birthday).getTime() || undefined,
                });

                if (res) {
                    const { status } = res;
                    if (status === 200 || status === 204 || status === 304) {
                        toast.success('Успешно сохранено', {
                            position: 'top-right',
                        });
                        dispatch(asyncLoadPatients());
                        setSaveActive(false);
                    }
                }
            } catch (error: unknown) {
                console.error(
                    (error as unknown as { message: string }).message
                );
            }
        }
        setIsLoading(false);
    };
    return (
        <form>
            <div className={styles.patientEditInfo__container}>
                <div className={styles.patientEditInfo__photoContainer}>
                    <UserPhotoUpload
                        setSelectedFile={setSelectedFile}
                        selectedPhotoUrl={selectedFileUrl}
                        setSelectedPhotoUrl={setSelectedFileUrl}
                        onChange={() => setSaveActive(true)}
                    />
                    <div className={styles.patientEditInfo__sexFieldContainer}>
                        <PatientSexField
                            sex={sex}
                            onChange={(value: Sex) => {
                                setSex(value);
                                setSaveActive(true);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.patientEditInfo__fieldsContainer}>
                    <InputField
                        label="Имя"
                        placeholder="Введите имя пациента"
                        fieldName="name"
                        type="PLAINTEXT"
                        register={register('name', {
                            ...validators['NAME'],
                            onChange: () => setSaveActive(true),
                        })}
                        errorText={errors.name?.message}
                        className={styles.patientEditInfo__input}
                    />
                    <InputField
                        label="Фамилия"
                        placeholder="Введите фамилию пациента"
                        fieldName="surname"
                        type="PLAINTEXT"
                        register={register('surname', {
                            ...validators['NAME'],
                            onChange: () => setSaveActive(true),
                        })}
                        errorText={errors.surname?.message}
                        className={styles.patientEditInfo__input}
                    />
                    <InputField
                        label="Отчество"
                        placeholder="Введите отчество пациента"
                        fieldName="middleName"
                        isRequired={false}
                        type="PLAINTEXT"
                        register={register('middleName', {
                            ...validators['NAME'],
                            onChange: () => setSaveActive(true),
                            required: false,
                        })}
                        errorText={errors.middleName?.message}
                        className={styles.patientEditInfo__input}
                    />
                    <InputField
                        label="Дата рождения"
                        placeholder="Введите дату рождения"
                        fieldName="birthday"
                        type="DATE"
                        register={register('birthday', {
                            ...validators['BIRTHDAY'],
                            onChange: () => setSaveActive(true),
                        })}
                        errorText={errors.birthday?.message}
                        control={control}
                        className={styles.patientEditInfo__input}
                    />
                </div>
                <div className={styles.patientEditInfo__saveContainer}>
                    <div className={styles.patientEditInfo__emailContainer}>
                        {isEmailUsed ? (
                            <>
                                <InputField
                                    label="Email"
                                    placeholder="Введите email пациента"
                                    fieldName="email"
                                    isRequired={false}
                                    type="EMAIL"
                                    register={register('email', {
                                        ...validators['EMAIL'],
                                        onChange: () => setSaveActive(true),
                                        required: false,
                                    })}
                                    errorText={errors.email?.message}
                                    className={
                                        styles.patientEditInfo__input_email
                                    }
                                />
                                {/*Не удалять*/}
                                {/*<Checkbox*/}
                                {/*    isDisabled={false}*/}
                                {/*    isChecked={shouldNotify}*/}
                                {/*    onChange={() => {*/}
                                {/*        setNotify(!shouldNotify);*/}
                                {/*    }}*/}
                                {/*    register={register('patient-notify', {*/}
                                {/*        onChange: () => {*/}
                                {/*            setNotify(!shouldNotify);*/}
                                {/*        },*/}
                                {/*    })}*/}
                                {/*    label="Отправить письмо об учетной записи пациенту"*/}
                                {/*    name="patient-notify"*/}
                                {/*/>{' '}*/}
                            </>
                        ) : (
                            <div
                                className={styles.patientEditInfo__addButton}
                                onClick={() => setEmailUsed(true)}
                            >
                                Добавить почту пациента
                            </div>
                        )}
                    </div>
                    <div className={saveButtonStyles.container}>
                        <div
                            className={`${saveButtonStyles.save_button} ${
                                isSaveActive ? '' : saveButtonStyles.inactive
                            }`}
                            onClick={() => {
                                if (isSaveActive)
                                    handleSubmit(updatePatientInfo)();
                            }}
                        >
                            <div className={saveButtonStyles.title}>
                                {isLoading ? <Spinner /> : 'Сохранить'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default PatientEditInfoPage;
