import React, { useRef, useMemo } from 'react';
import './RangeSlider.scss';

const RangeSlider = ({
    children,
    setValue,
    value,
}: {
    children: JSX.Element | string | undefined;
    setValue: (value: number) => void;
    value: number;
}): JSX.Element => {
    const rangeSliderRef = useRef<HTMLInputElement>(null);

    const onChange = () => {
        const value = Number(rangeSliderRef.current?.value);
        setValue(value);
    };

    const id = useMemo(() => Math.random(), []);

    return (
        <div className="trg-range-slider">
            <label htmlFor={`slider-${id}`} className="trg-range-slider__label">
                {children}
            </label>
            <input
                id={`slider-${id}`}
                ref={rangeSliderRef}
                name="slider"
                type="range"
                className="trg-range-slider__input"
                defaultValue={value ?? 0}
                onChange={onChange}
                step={0.01}
                min={-1}
                max={1}
            />
        </div>
    );
};

export default RangeSlider;
