import React, { useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom';

import closeIcon from '@assets/images/close_grey.svg';

import styles from './Modal.module.scss';

interface IModal {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    title: string;
    disableDefaultPadding?: boolean;
}

export const Modal: React.FC<IModal> = ({
    isOpen,
    onClose,
    children,
    title,
    disableDefaultPadding,
}) => {
    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                (event.target as HTMLElement).classList.contains(
                    styles.modalOverlay
                )
            ) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                    <span className={styles.title}>{title}</span>
                    <button className={styles.modalClose} onClick={onClose}>
                        <img
                            src={closeIcon}
                            className={styles.modalCloseIcon}
                            alt="closeIcon"
                        />
                    </button>
                </div>
                <div
                    className={
                        disableDefaultPadding
                            ? styles.noPaddingBody
                            : styles.modalBody
                    }
                >
                    {children}
                </div>
            </div>
        </div>,
        document.body
    );
};
