import React, { useState } from 'react';

import styles from './UserPhotoUpload.module.scss';
import default_icon from '@assets/images/upload.svg';

type userPhotoUploadProps = {
    setSelectedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
    selectedPhotoUrl: string;
    setSelectedPhotoUrl: React.Dispatch<React.SetStateAction<string>>;
    onChange?: () => void;
};

export function UserPhotoUpload({
    selectedPhotoUrl,
    setSelectedPhotoUrl,
    setSelectedFile,
    onChange,
}: userPhotoUploadProps): JSX.Element {
    const [fileError, setFileError] = useState('');

    function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
        setFileError('');
        const file = e.target.files?.[0];

        if (file) {
            const t = file.type;

            if (
                t != 'image/jpeg' &&
                t != 'image/jpg' &&
                t != 'image/png' &&
                t != 'image/bmp' &&
                t != 'image/webp'
            ) {
                setFileError('Please select a valid image file');
                return;
            }

            setSelectedPhotoUrl(URL.createObjectURL(file));
            setSelectedFile(file);
            if (onChange) onChange();
        }
    }

    return (
        <div className={styles.container}>
            <label htmlFor="photoUpload">
                <div
                    className={styles.photo}
                    style={{
                        backgroundImage: `url(${
                            selectedPhotoUrl === ''
                                ? default_icon
                                : selectedPhotoUrl
                        })`,
                        backgroundSize:
                            selectedPhotoUrl === '' ? '40px 40px' : 'cover',
                    }}
                >
                    <p />
                </div>
            </label>
            <input
                type="file"
                name="photoUpload"
                onChange={handleFileUpload}
                id="photoUpload"
                hidden={true}
                accept="image/*"
            />
            {fileError ? <span>{fileError}</span> : null}
        </div>
    );
}
