import React from 'react';
import styles from './Button.module.scss';
import LoadingSpinner from '@components/common/loadingspinner/LoadingSpinner';
import { classes } from '@utils/classNames';

const Button = ({
    text,
    icon,
    iconPosition = 'LEFT',
    isActive = true,
    onClick,
    isLoading = false,
    color = 'BLUE',
    size = 'MEDIUM',
    padding,
    hoverTextForInactive,
}: {
    text?: string;
    icon?: string;
    iconPosition?: 'LEFT' | 'RIGHT';
    isActive?: boolean;
    onClick?: any;
    isLoading?: boolean;
    color?: 'BLUE' | 'GRAY' | 'WHITE';
    size?: 'MEDIUM' | 'BIG' | 'SMALL' | 'EXTRA_SMALL';
    padding?: string;
    hoverTextForInactive?: string;
}) => {
    return (
        <button
            className={classes(styles.button, {
                [styles.button_gray]: color === 'GRAY',
                [styles.button_white]: color === 'WHITE',
                [styles.button_inactive]: !isActive,
                [styles.button_big]: size === 'BIG',
                [styles.button_small]: size === 'SMALL',
                [styles.button_extraSmall]: size === 'EXTRA_SMALL',
            })}
            onClick={isLoading || !isActive ? undefined : onClick}
            style={{ padding: padding ?? undefined }}
        >
            <div
                className={styles.button__content}
                style={{ opacity: isLoading ? 0 : 1 }}
            >
                {icon && iconPosition === 'LEFT' && (
                    <img
                        src={icon}
                        alt="icon"
                        className={styles.button__icon}
                    />
                )}
                <span className={styles.button__text}>{text}</span>
                {icon && iconPosition === 'RIGHT' && (
                    <img
                        src={icon}
                        alt="icon"
                        className={styles.button__icon}
                    />
                )}
            </div>
            {isLoading && (
                <div className={styles.button__loaderContainer}>
                    <LoadingSpinner
                        width={size === 'EXTRA_SMALL' ? 8 : 20}
                        height={size === 'EXTRA_SMALL' ? 8 : 20}
                        stroke={
                            color === 'BLUE' ? 'var(--white)' : 'var(--gray8)'
                        }
                    />
                </div>
            )}
            {hoverTextForInactive && !isActive && (
                <div className={styles.button__hoverTextBlock}>
                    <span className={styles.button__hoverText}>
                        {hoverTextForInactive}
                    </span>
                </div>
            )}
        </button>
    );
};

export default Button;
