import React, { useCallback, useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import { useLocation } from 'react-router';
import { TrgAPIs } from '@APIs/trg.apis';
import { ITrgReport, ITrgReportValue } from '@interfaces/trg-report.interface';
import { useDebounce } from '@hooks/useDebounce';
import differenceInYears from 'date-fns/differenceInYears';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';
import { toast } from 'react-toastify';
import { ageToStr } from '@utils/utils';
import TrgReportContent from './TrgContent/TrgReportContent';

type ChartType = [
    number,
    number,
    number,
    {
        method: string;
        status: string;
        value: [
            {
                value: number;
            },
            {
                value: number;
            }
        ];
    },
    number | string
];

type SourceObjectType = {
    [key: string]: {
        x: number;
        y: number;
    };
};

export default function TrgReport(): JSX.Element {
    const [report_id, setReportId] = useState<string>();
    const [report, setReport] = useState<ITrgReport | null>(null);
    const [img, setImage] = useState<CanvasImageSource | undefined>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDiagramShown, setDiagramShown] = useState(false);
    const [isPhotoShown, setIsPhotoShown] = useState(true);
    const [points, setPoints] = useState<{
        [key: string]: { x: number; y: number };
    }>({});
    const [charts_size, setChartsSize] = useState({
        width: 0,
        height: 0,
    });
    const location = useLocation<{
        trg_id: string;
        method_id: string;
    }>();
    const [charts, setCharts] = useState<ChartType[]>([]);
    const [birthday, setBirthday] = useState('');
    const [patient_name, setPatientName] = useState('');
    const [report_date, setReportDate] = useState('');
    const [results, setResults] = useState<ITrgReportValue[]>([]);
    const [container_size, setContainerSize] = useState({
        width: 357,
        height: 300,
    });
    const [image_size, setImageSize] = useState<{
        width: number;
        height: number;
    } | null>();
    const [scale, setScale] = useState(1);
    const [clicksMade, setClicksMade] = useState(0);
    const [isPrinting, setIsPrinting] = useState(false);

    useEffect(() => {
        if (report) {
            const tmp = new Image();
            tmp.addEventListener('load', () => {
                setImage(tmp);
                setImageSize({
                    width: tmp.width,
                    height: tmp.height,
                });
            });
            tmp.src = report.photo;
            setPatientName(
                `${report.patient?.name ?? ''} ${
                    report.patient?.surname ?? ''
                } ${report.patient?.middleName ?? ''}`
            );
            setReportDate(
                dateFormat(new Date(report.updated_at), 'dd.mm.yyyy')
            );
            if (report.patient?.dateOfBirth) {
                setBirthday(
                    `${dateFormat(
                        new Date(report.patient.dateOfBirth),
                        'dd.mm.yyyy'
                    )} (${ageToStr(
                        differenceInYears(
                            new Date(),
                            new Date(report.patient.dateOfBirth)
                        )
                    )})`
                );
            }
            setResults(report.values);
        }
    }, [report]);

    const fit = useCallback(() => {
        const div = document.getElementById('trg-report__image');
        if (div) {
            let divHeight = div.offsetHeight;
            if (image_size) {
                setScale(div.offsetWidth / image_size.width);
                divHeight =
                    (image_size.height * div.offsetWidth) / image_size.width;
            }
            setContainerSize({
                height: divHeight,
                width: div.offsetWidth,
            });
        }
    }, [image_size]);

    useEffect(() => {
        setChartsSize({
            height: results.length * 88,
            width: 300,
        });

        setCharts(
            results.map((res) => {
                return [res.min, res.max, res.value, res.norm, res.title];
            })
        );
    }, [results]);

    useEffect(() => {
        setIsLoaded(false);
        TrgAPIs.calculate(location.state.trg_id, location.state.method_id)
            .then(({ data }) => {
                setReportId(data.id);
                TrgAPIs.getReport(data.id)
                    .then((res) => {
                        TrgAPIs.getMethods().then((methodsData) => {
                            const methodPoints = methodsData.data
                                .find((method) => method.id === res.data.method)
                                ?.points.map((point) => point.name);
                            if (methodPoints) {
                                // TODO: Чисто технически, если в объекте хранится метод, то можно фильтровать точки на бэке. На будущее можно переделать
                                const filteredPoints =
                                    methodPoints.reduce<SourceObjectType>(
                                        (acc, key) => {
                                            if (
                                                res.data.points.hasOwnProperty(
                                                    key
                                                )
                                            ) {
                                                acc[key] = res.data.points[key];
                                            }
                                            return acc;
                                        },
                                        {}
                                    );
                                setPoints(filteredPoints);
                            } else {
                                setPoints(res.data.points);
                            }
                        });
                        setReport(res.data);
                        setIsLoaded(true);
                    })
                    .catch((err) => {
                        if (err.response) {
                            toast.error(err.response.data.message);
                        }
                    });
            })
            .catch((err) => {
                if (err.response) {
                    toast.error(err.response.data.message);
                }
                setIsLoaded(true);
            });

        window.addEventListener('resize', fit);
        fit();
        return () => {
            window.removeEventListener('resize', fit);
        };
    }, []);

    useEffect(() => {
        fit();
    }, [image_size]);

    const onDescriptionChange: React.FormEventHandler<HTMLTextAreaElement> =
        useCallback(
            useDebounce((e: { target: { value: string } }) => {
                setReport((curr) => {
                    if (curr) {
                        return {
                            ...curr,
                            description: e.target.value,
                        };
                    }
                    return curr;
                });
                if (report_id) {
                    TrgAPIs.updateTrgResult(report_id, e.target.value).catch(
                        (err) => {
                            if (err.response) {
                                toast.error(err.response.data.message);
                            }
                        }
                    );
                }
            }, 450),
            [report_id]
        );

    return !isLoaded ? (
        <CustomLoader size={50} />
    ) : (
        <TrgReportContent
            report={report}
            img={img}
            points={points}
            scale={scale}
            setScale={setScale}
            clicksMade={clicksMade}
            setClicksMade={setClicksMade}
            isDiagramShown={isDiagramShown}
            setDiagramShown={setDiagramShown}
            isPhotoShown={isPhotoShown}
            setIsPhotoShown={setIsPhotoShown}
            results={results}
            charts={charts}
            charts_size={charts_size}
            container_size={container_size}
            image_size={image_size}
            report_date={report_date}
            patient_name={patient_name}
            birthday={birthday}
            onDescriptionChange={onDescriptionChange}
            isPrinting={isPrinting}
            setIsPrinting={setIsPrinting}
        />
    );
}
