import React, { useEffect } from 'react';
import PointConnectedSvg from '@images/point-connected.svg';
import PointUnconnectedSvg from '@images/point-unconnected.svg';
import { ITrgEditPoint } from '@interfaces/trg-method.interface';

export function PointList({
    points,
    onPointSelect,
    onPointDisconnect,
    selectedPointName,
}: {
    points: ITrgEditPoint[];
    onPointSelect: (pointName: string) => void;
    onPointDisconnect: (key: string) => void;
    selectedPointName: string | null;
}): JSX.Element {
    const refs: { [key: string]: HTMLOListElement | null } = {};

    useEffect(() => {
        if (selectedPointName && refs[selectedPointName]) {
            refs[selectedPointName]?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [selectedPointName]);
    return (
        <>
            <div className="add-trg__points-container">
                <li className="add-trg__points">
                    {points.map((point) => (
                        <ol
                            className="add-trg__point-list-item"
                            key={point.name}
                            ref={(el) => (refs[point.name] = el)}
                        >
                            <div
                                className={`add-trg__point-item ${
                                    point.is_active
                                        ? 'add-trg__point-item_active'
                                        : selectedPointName === point.name
                                        ? 'add-trg__point-item_on-cursor'
                                        : ''
                                }`}
                                onClick={() => onPointSelect(point.name)}
                            >
                                <span className="add-trg__point-title">
                                    <h4>Точка {point.name}</h4>
                                    <p>{point.description}</p>
                                </span>

                                <img
                                    alt={'delete icon'}
                                    onClick={() =>
                                        point.is_active &&
                                        onPointDisconnect(point.name)
                                    }
                                    className={
                                        point.is_active
                                            ? 'add-trg__point-icon'
                                            : 'add-trg__transparent'
                                    }
                                    src={
                                        (point.is_active
                                            ? PointConnectedSvg
                                            : PointUnconnectedSvg) as string
                                    }
                                />
                            </div>
                        </ol>
                    ))}
                </li>
            </div>
            {selectedPointName && (
                <div className="add-trg__point-description">
                    <img
                        alt={'description'}
                        src={
                            points.find(
                                (point) => point.name === selectedPointName
                            )?.image
                        }
                    />
                </div>
            )}
        </>
    );
}
