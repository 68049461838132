import React, { useState } from 'react';
import style from './TrgToolButton.module.scss';

export default function TrgToolButton({
    icon,
    title,
    tip,
    isEnabled,
    isActive,
    forceMessage,
    onClick,
}: {
    icon: string;
    tip: string;
    title?: string;
    isEnabled: boolean;
    isActive: boolean;
    forceMessage?: boolean;
    onClick: () => void;
}): JSX.Element {
    const [isMessageVisible, setIsMessageVisible] = useState(false);

    let isEnter = false;

    return (
        <div>
            <div
                className={
                    isEnabled
                        ? isActive
                            ? style.Active
                            : style.Inactive
                        : style.Disabled
                }
                style={{
                    width: title ? '100%' : '44px',
                    marginRight: title ? '4px' : '0px',
                }}
                onClick={() => {
                    isEnter = false;
                    setIsMessageVisible(!isEnabled);
                    if (isEnabled) onClick();
                }}
                onMouseEnter={() => {
                    isEnter = true;
                    setTimeout(() => {
                        if (isEnter) setIsMessageVisible(true);
                    }, 500);
                }}
                onMouseLeave={() => {
                    isEnter = false;
                    setIsMessageVisible(false);
                }}
            >
                <img className={style.Image} src={icon} />
                <p>{title}</p>
            </div>
            {(isMessageVisible || (forceMessage && isActive)) && (
                <div className={style.Message}>{tip}</div>
            )}
        </div>
    );
}
