import { TrgAPIs } from '@APIs/trg.apis';

export const requestTRG: (trgId?: string) => Promise<boolean> = async (
    trgId?: string
) => {
    if (!trgId) {
        return false;
    }

    try {
        const { data } = await TrgAPIs.one(trgId);
        return (data && data.is_verified) || false;
    } catch {
        return false;
    }
};
