import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import './PatientInfoPage.scss';

import PatientInfoPresentationsPage from './pages/presentations/PatientInfoPresentationsPage';
import PatientInfoHeaderMenu from './patientmenu/PatientInfoHeaderMenu';

import { Item } from './patientmenu/PatientInfoHeaderMenu';

import { ROUTES } from '@constants/routes';
import { PatientEditInfoPage } from './pages/fields/PatientEditInfoPage';
import PresentationStorePage from '@components/store/PresentationStorePage';
import { useAppSelector } from '@hooks/useAppSelector';
import {
    selectSelectedPatient,
    setSelectedPatient,
} from '@store/feature/patient.slice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import {
    selectPatients,
    selectPatientsState,
} from '@store/feature/patients.slice';
import ClientTrgTable from '@components/ClientTrgTable/ClientTrgTable';
import PhotoArchive from '@components/photoArchive/photoArchive';
import { PhotoCollections } from '@pages/PhotoCollections/PhotoCollections';
import PhotoTemplatesPage from '@pages/PhotoCollection/PhotoTemplatesPage';
import { toast } from 'react-toastify';
import { asyncLoadCollections } from '@feature/collections.slice';
import PresentationStoreFromCollectionPage from '@pages/PresentationStoreFromCollectionPage/PresentationStoreFromCollectionPage';

const menuItem: Item[] = [
    {
        title: 'Презентации',
        selected: false,
        path: ROUTES.PRESENTATIONS.END,
    },
    {
        title: 'Фотоколлекции',
        selected: true,
        path: ROUTES.PHOTOS.END,
    },
    {
        title: 'Расчеты',
        selected: false,
        path: ROUTES.TRG.END,
    },
    {
        title: 'О пациенте',
        selected: false,
        path: ROUTES.INFO.END,
    },
];
// Не удалять

// if (process.env.APP_MODE === 'IN_TEST') {
//     menuItem.unshift({
//         title: 'Хранилище',
//         selected: false,
//         path: ROUTES.STORAGE.END,
//     });
// }

const PatientInfoPage = (): JSX.Element => {
    const { patientId } = useParams<{ patientId: string }>();

    const history = useHistory();

    const patients = useAppSelector(selectPatients);
    const selectedPatient = useAppSelector(selectSelectedPatient);
    const isPatientsLoading = useAppSelector(selectPatientsState);
    const dispatch = useAppDispatch();

    const [title, setTitle] = useState('');

    useEffect(() => {
        if (history.location.pathname.endsWith('/store')) {
            setTitle('Доступные презентации');
        } else {
            if (selectedPatient) {
                const { surname, name, middleName } = selectedPatient;
                setTitle(`${surname ?? ''} ${name ?? ''} ${middleName ?? ''}`);
            } else {
                setTitle('Новый пользователь');
            }
        }
    }, [history, selectedPatient]);

    useEffect(() => {
        if (!isPatientsLoading) {
            const foundPatient = patients.find(
                (patient) => patient.id === patientId
            );
            if (!foundPatient) {
                toast.error('Пользователь не найден');
                dispatch(setSelectedPatient(null));
                history.replace(ROUTES.PATIENTS.END);
            } else {
                dispatch(setSelectedPatient(foundPatient));
                dispatch(asyncLoadCollections(foundPatient.id));
            }
        }
    }, [patientId, patients]);

    return (
        <div className="user-info__container-root">
            <div className="user-info__header-container_patient">
                <div
                    onClick={() => history.goBack()}
                    className="user-info__header-back"
                />
                <Switch>
                    <Route path={ROUTES.PATIENTS.$(patientId).STORE.END}>
                        <p className="user-info__title">
                            Доступные презентации
                        </p>
                    </Route>
                    <Route path={ROUTES.PATIENTS.$(patientId).END}>
                        <p className="user-info__title">{title}</p>
                        <PatientInfoHeaderMenu items={menuItem} />
                    </Route>
                </Switch>
            </div>
            <div className="user-info__switch-container">
                <Switch>
                    <Route exact path={ROUTES.PATIENTS.$(':patientId').END}>
                        <Redirect
                            to={ROUTES.PATIENTS.$(patientId).PHOTOS.END}
                        />
                    </Route>
                    <Route
                        exact
                        path={ROUTES.PATIENTS.$(':patientId').PHOTOS.END}
                    >
                        <PhotoCollections />
                    </Route>
                    <Route
                        exact
                        path={ROUTES.PATIENTS.$(':patientId').STORAGE.END}
                    >
                        <PhotoArchive />
                    </Route>
                    <Route
                        exact
                        path={
                            ROUTES.PATIENTS.$(':patientId').STORAGE.$(
                                ':folderId'
                            ).END
                        }
                    >
                        <PhotoArchive />
                    </Route>
                    <Route
                        exact
                        path={
                            ROUTES.PATIENTS.$(':patientId').PHOTOS.$(
                                ':collectionId'
                            ).END
                        }
                    >
                        <PhotoTemplatesPage />
                    </Route>
                    <Route
                        path={ROUTES.PATIENTS.$(':patientId').PRESENTATIONS.END}
                    >
                        <PatientInfoPresentationsPage />
                    </Route>
                    <Route path={ROUTES.PATIENTS.$(':patientId').INFO.END}>
                        <PatientEditInfoPage />
                    </Route>
                    <Route
                        path={
                            ROUTES.PATIENTS.$(
                                ':patientId'
                            ).STORE.FROM_COLLECTION.$(':collectionId').END
                        }
                    >
                        <PresentationStoreFromCollectionPage />
                    </Route>
                    <Route
                        exact
                        path={ROUTES.PATIENTS.$(':patientId').STORE.END}
                    >
                        <PresentationStorePage />
                    </Route>

                    <Route path={ROUTES.PATIENTS.$(':patientId').TRG.END}>
                        <ClientTrgTable />
                    </Route>
                </Switch>
            </div>
        </div>
    );
};

export default PatientInfoPage;
