import React from 'react';
import { Link } from 'react-router-dom';
import './cell-button.scss';

export function CellButtonComponent({
    to,
    children,
    onClick,
}: {
    to: string;
    children: JSX.Element[] | JSX.Element | string | undefined;
    onClick?: () => void;
}): JSX.Element {
    return (
        <div className="cell-button">
            <Link to={to}>
                <div className="cell-button__container" onClick={onClick}>
                    <p className="cell-button__label">{children}</p>
                </div>
            </Link>
        </div>
    );
}
