import React from 'react';

import { classes } from '@utils/classNames';
import LoadingSpinner from '@components/common/loadingspinner/LoadingSpinner';

import styles from './Button.module.scss';

export enum ButtonState {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    WARNING = 'WARNING',
    SUCCESS = 'SUCCESS',
}

const Button = ({
    title,
    state = ButtonState.ACTIVE,
    onClick,
    extraStyle,
    isLoading,
    error,
    type = 'button',
    disabled,
    className,
}: {
    title: string;
    state?: ButtonState;
    onClick?: () => void;
    extraStyle?: React.CSSProperties;
    isLoading?: boolean;
    error?: string;
    type?: 'button' | 'submit';
    disabled?: boolean;
    className?: string;
}): JSX.Element => {
    const style = () => {
        if (disabled) {
            return styles.InactiveContainer;
        }

        switch (state) {
            case ButtonState.INACTIVE:
                return styles.InactiveContainer;
            case ButtonState.ACTIVE:
                return styles.ActiveContainer;
            case ButtonState.WARNING:
                return styles.WarningContainer;
            case ButtonState.SUCCESS:
                return styles.SuccessContainer;
        }
    };

    const classRoot = style();

    return (
        <div className={className}>
            <button
                onClick={onClick}
                className={classes(classRoot, styles.root)}
                style={extraStyle}
                type={type}
            >
                {isLoading ? <LoadingSpinner /> : title}
            </button>
            {error && <span className={styles.errorMsg}>{error}</span>}
        </div>
    );
};

export default Button;
