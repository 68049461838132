import React from 'react';
import styles from './Notification.module.scss';

export function ErrorNotification(): JSX.Element {
    return (
        <div
            className={styles.container}
            style={{ border: '1px solid var(--red)' }}
        >
            <h3>Произошла ошибка!</h3>
            <p>Текст ошибки типо что то случилось или еще что</p>
            <svg
                width="50"
                height="48"
                viewBox="0 0 50 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10 43.1953H39.9414C43.5156 43.1953 45.9375 40.4219 45.9375 37.1797C45.9375 36.1836 45.6836 35.168 45.1367 34.2109L30.1562 8.01953C28.9844 6.00781 27.0117 4.99219 24.9609 4.99219C22.9297 4.99219 20.8984 6.02734 19.7656 8.01953L4.76562 34.2305C4.23828 35.168 3.98438 36.1836 3.98438 37.1797C3.98438 40.4219 6.40625 43.1953 10 43.1953ZM10.9961 38.2734C10.0391 38.2734 9.41406 37.4922 9.41406 36.6328C9.41406 36.3984 9.45312 36.0664 9.58984 35.8125L23.5938 11.1641C23.8867 10.6562 24.4336 10.4414 24.9609 10.4414C25.4883 10.4414 26.0156 10.6758 26.3086 11.1836L40.3125 35.832C40.4492 36.1055 40.5078 36.3984 40.5078 36.6328C40.5078 37.4922 39.8633 38.2734 38.9258 38.2734H10.9961ZM24.9805 28.9961C26.2891 28.9961 27.0508 28.2734 27.1094 26.9062L27.4219 19.1328C27.5 17.707 26.4648 16.7305 24.9609 16.7305C23.457 16.7305 22.4414 17.707 22.5195 19.1133L22.8125 26.9453C22.8906 28.2539 23.6523 28.9961 24.9805 28.9961ZM24.9805 35.7148C26.4844 35.7148 27.6172 34.7773 27.6172 33.3516C27.6172 31.9258 26.4844 30.9883 24.9805 30.9883C23.4766 30.9883 22.3242 31.9258 22.3242 33.3516C22.3242 34.7773 23.4961 35.7148 24.9805 35.7148Z"
                    fill="#FF3366"
                />
            </svg>
        </div>
    );
}
