import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Image as KImage } from 'react-konva';
import { TrgReportPoint } from '../common/report-point/trg-report-point';
import { Checkbox } from '@components/checkbox/Checkbox';
import { ITrgReportValue } from '@interfaces/trg-report.interface';
import ResultTableBody from '../ResultTableBody/ResultTableBody';
import TrgReportPdf from '@components/TrgReport/TrgReportPDF/TrgReportPdf';
import { useAuth } from '@contexts/AuthContext';
import Konva from 'konva';
import styles from './TrgReportContent.module.scss';
import { classes } from '@utils/classNames';

type Props = {
    report: any;
    img: CanvasImageSource | undefined;
    points: { [key: string]: { x: number; y: number } };
    scale: number;
    setScale: (scale: number) => void;
    clicksMade: number;
    setClicksMade: (clicksMade: number) => void;
    isDiagramShown: boolean;
    setDiagramShown: (isDiagramShown: boolean) => void;
    isPhotoShown: boolean;
    setIsPhotoShown: (isPhotoShown: (prev: boolean) => boolean) => void;
    results: ITrgReportValue[];
    charts: any[];
    charts_size: { width: number; height: number };
    container_size: { width: number; height: number };
    image_size: { width: number; height: number } | null | undefined;
    report_date: string;
    patient_name: string;
    birthday: string;
    onDescriptionChange: React.FormEventHandler<HTMLTextAreaElement>;
    isPrinting: boolean;
    setIsPrinting: (isPrinting: boolean) => void;
};

const TrgReportContent: React.FC<Props> = ({
    report,
    img,
    points,
    scale,
    setScale,
    clicksMade,
    setClicksMade,
    isDiagramShown,
    setDiagramShown,
    isPhotoShown,
    setIsPhotoShown,
    results,
    charts,
    charts_size,
    container_size,
    image_size,
    report_date,
    patient_name,
    birthday,
    onDescriptionChange,
    isPrinting,
    setIsPrinting,
}) => {
    const stage = useRef<Konva.Stage>(null);
    const [fileName, setFileName] = useState('');
    let lastPointPosition = { x: 0, y: 0 };
    let lastPosition = { x: 0, y: 0 };
    let isDraggable = false;

    useEffect(() => {
        const date = new Date(report.created_at).toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: undefined,
        });
        setFileName(`ТРГ_${report.patient.surname}_${date}`);
    }, [report]);

    const onMouseUp = () => {
        isDraggable = false;
    };

    const onMouseDown = () => {
        isDraggable = true;

        lastPointPosition = stage.current?.getPointerPosition() ?? {
            x: 0,
            y: 0,
        };
        lastPosition = stage.current?.position() ?? { x: 0, y: 0 };
    };

    const onMouseMove = () => {
        if (!isDraggable) return;

        const pointPosition = stage.current?.getPointerPosition() ?? {
            x: 0,
            y: 0,
        };
        let oldX = 0;
        let oldY = 0;
        if (stage.current) {
            oldX = stage.current?.getPosition().x;
            oldY = stage.current?.getPosition().y;
        }
        const newX = lastPosition.x + (pointPosition.x - lastPointPosition.x);
        const newY = lastPosition.y + (pointPosition.y - lastPointPosition.y);
        let imgWidth = 0;
        let imgHeight = 0;
        if (image_size) {
            imgWidth = image_size.width;
            imgHeight = image_size.height;
        }
        if (
            stage.current?.width() &&
            newX + imgWidth * scale < stage.current?.width()
        ) {
            if (newX >= oldX) oldX = newX;
        } else if (newX <= 0) oldX = newX;
        if (
            stage.current?.height() &&
            newY + imgHeight * scale < stage.current?.height()
        ) {
            if (newY >= oldY) oldY = newY;
        } else if (newY <= 0) oldY = newY;
        stage.current?.position({
            x: oldX,
            y: oldY,
        });
        return;
    };

    const zoom = (scaling: number) => {
        if (scaling > 1) {
            if (clicksMade >= 8) return;
            setClicksMade(clicksMade + 1);
        } else {
            if (clicksMade <= 0) return;
            setClicksMade(clicksMade - 1);
        }
        setScale(scale * scaling);
    };

    const { profile } = useAuth();

    return (
        <div className={styles.trgReportContent__container}>
            <header className={styles.trgReportContent__header}>
                <div className={styles.trgReportContent__headerContainer}>
                    <div
                        onClick={() => history.back()}
                        className={styles.trgReportContent__headerBack}
                    />
                    <p className={styles.trgReportContent__title}>Отчет</p>
                </div>
                <div className={styles.trgReportContent__tabs}>
                    <div>
                        <button
                            onClick={() => setIsPrinting(true)}
                            className={styles.trgReportContent__saveButton}
                        >
                            Напечатать
                        </button>
                    </div>
                </div>
            </header>
            <div className={styles.trgReportContent__info}>
                <div>
                    <div className={styles.trgReportContent__infoName}>
                        {report?.patient.avatar ? (
                            <img
                                src={report.patient.avatar}
                                className={styles.trgReportContent__infoAvatar}
                                alt={'avatar image'}
                            />
                        ) : (
                            <div
                                className={styles.trgReportContent__infoAvatar}
                            ></div>
                        )}
                        {patient_name}
                    </div>
                    <div className={styles.trgReportContent__infoBirthdate}>
                        {birthday}
                    </div>
                </div>
                <div>
                    <div
                        className={styles.trgReportContent__diagramButton}
                        onClick={() => setDiagramShown(!isDiagramShown)}
                    >
                        {isDiagramShown
                            ? 'Скрыть диаграмму'
                            : 'Показать диаграмму'}
                    </div>
                    <div>
                        Дата отчета: <span>{report_date}</span>
                    </div>
                </div>
            </div>
            <div
                className={styles.trgReportContent__body}
                onWheel={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className={styles.trgReportContent__imageWrapper}>
                    <div
                        className={styles.trgReportContent__image}
                        id="trg-report__image"
                        style={{
                            backgroundColor: report?.bgColor,
                            ...container_size,
                        }}
                    >
                        <div className={styles.trgReportContent__scaleButtons}>
                            <div
                                className={styles.trgReportContent__buttonPlus}
                                onClick={() => zoom(1.2)}
                            >
                                <div
                                    className={classes(
                                        styles.trgReportContent__plusIcon,
                                        { [styles.disabled]: clicksMade >= 8 }
                                    )}
                                ></div>
                            </div>
                            <div
                                className={styles.trgReportContent__buttonMinus}
                                onClick={() => zoom(1 / 1.2)}
                            >
                                <div
                                    className={classes(
                                        styles.trgReportContent__minussIcon,
                                        { [styles.disabled]: clicksMade <= 0 }
                                    )}
                                ></div>
                            </div>
                        </div>
                        <Stage
                            ref={stage}
                            scaleX={scale}
                            scaleY={scale}
                            {...container_size}
                            onMouseDown={onMouseDown}
                            onMouseUp={onMouseUp}
                            onMouseMove={onMouseMove}
                            className={styles.trgResultImage}
                        >
                            <Layer>
                                <KImage image={img} />
                                {Object.entries(points).map(
                                    ([key, { x, y }]) => (
                                        <TrgReportPoint
                                            key={key}
                                            x={x}
                                            y={y}
                                            radius={1}
                                            title={key}
                                        />
                                    )
                                )}
                            </Layer>
                        </Stage>
                    </div>
                    <div className={styles.trgReportContent__conclusion}>
                        <h2
                            className={styles.trgReportContent__conclusionTitle}
                        >
                            Вывод
                        </h2>
                        <textarea
                            onChangeCapture={onDescriptionChange}
                            className={
                                styles.trgReportContent__conclusionDescription
                            }
                            defaultValue={report?.description}
                            placeholder={'Введите текст'}
                        />
                    </div>
                    <div className={styles.trgReportContent__checkboxContainer}>
                        <Checkbox
                            isChecked={!isPhotoShown}
                            isDisabled={false}
                            onChange={() => setIsPhotoShown((prev) => !prev)}
                            label={'Не отображать фото в ПДФ'}
                            name={'photo-visibility'}
                        />
                    </div>
                </div>
                <table
                    className={styles.trgReportContent__result}
                    cellPadding="0"
                >
                    <ResultTableBody
                        charts={charts}
                        charts_size={charts_size}
                        results={results}
                        areChartsShown={isDiagramShown}
                    />
                </table>
            </div>
            <div className={styles.trgReportContent__pdfContainer}>
                <TrgReportPdf
                    isPrinting={isPrinting}
                    setIsPrinting={setIsPrinting}
                    results={results}
                    img={img}
                    points={points}
                    isImgShown={isPhotoShown}
                    areChartsShown={isDiagramShown}
                    conclusion={report?.description}
                    charts={charts}
                    patientFullName={patient_name}
                    doctorFullName={`${profile.surname} ${profile.name} ${
                        profile.middleName ?? ''
                    }`}
                    fileName={fileName}
                />
            </div>
        </div>
    );
};

export default TrgReportContent;
