import { KonvaEventObject } from 'konva/lib/Node';
import React, { useContext, useCallback } from 'react';
import { Circle, Text, Group } from 'react-konva';
import { TrgContext } from '../edit-area/EditArea';

type PropsType = {
    draggable: boolean;
    radius: number;
    x: number;
    y: number;
    title: string;
    display?: boolean;
    dragMove: (e: KonvaEventObject<MouseEvent>, k: string) => void;
    dragEnd: (e: KonvaEventObject<MouseEvent>, k: string) => void;
    dragStart: (e: KonvaEventObject<MouseEvent>, k: string) => void;
    scale: {
        pointScale: number;
        fontScale: number;
    };
};

export function TrgPoint({
    draggable,
    radius,
    x,
    y,
    title,
    display = true,
    dragMove,
    dragEnd,
    dragStart,
    scale,
}: PropsType): JSX.Element {
    const { fontSize, isInverted } = useContext(TrgContext);

    const onMove = useCallback(
        (e: KonvaEventObject<DragEvent>) => {
            e.evt.preventDefault();
            dragMove(e, title);
        },
        [dragMove, title]
    );

    const onMoveStart = useCallback(
        (e: KonvaEventObject<DragEvent>) => {
            dragStart(e, title);
        },
        [dragStart, title]
    );

    const onMoveEnd = useCallback(
        (e: KonvaEventObject<DragEvent>) => {
            e.evt.preventDefault();
            dragEnd(e, title);
        },
        [dragEnd, title]
    );

    const mouseEnter = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            if (!draggable) return;
            const stage = e.target.getStage();
            if (stage) {
                stage.container().style.cursor = 'pointer';
            }
        },
        [draggable]
    );

    const mouseLeave = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            if (!draggable) return;
            const stage = e.target.getStage();
            if (stage) {
                stage.container().style.cursor = '';
            }
        },
        [draggable]
    );

    return display ? (
        <Group
            draggable={draggable}
            x={x}
            y={y}
            onDragMove={onMove}
            onDragEnd={onMoveEnd}
            onDragStart={onMoveStart}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            <Circle
                fill={['m0', 'm1'].includes(title) ? '#2A8BF2' : '#F00000'}
                scaleX={scale.pointScale}
                scaleY={scale.pointScale}
                radius={radius + 1}
                stroke={draggable ? '#FFF' : ''}
                strokeWidth={1}
            />
            <Text
                listening={false}
                x={radius * scale.pointScale + 2}
                y={radius * scale.pointScale + 2}
                text={title}
                fill={isInverted ? '#000' : '#FFF'}
                fontSize={fontSize * scale.fontScale}
                onContextMenu={(evt) => evt.evt.stopPropagation()}
            />
        </Group>
    ) : (
        <></>
    );
}
