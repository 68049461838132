import React, { useCallback, useState } from 'react';
import { Group, Line } from 'react-konva';
import { TrgPoint } from '@components/TrgEdit/TrgPoint/TrgPoint';
import { KonvaEventObject } from 'konva/lib/Node';
import { CanvasBaseKonva } from '@components/TrgEdit/edit-area/CanvasBase';
import { EditArea } from '@components/TrgEdit/edit-area/EditArea';
import { findPerpendiculars } from '@utils/utils';
import { ITrgEditPoint } from '@interfaces/trg-method.interface';
import { TrgToolEnum } from '@interfaces/tools.interface';
import { ITrg } from '@interfaces/trg.interface';
import { TRGState } from '@pages/TrgEdit/TrgEdit';

interface TrgEditDisplayProps {
    currentState: TRGState;
    trg: ITrg | undefined;
    points: ITrgEditPoint[];
    ruler: { [key: string]: { x: number; y: number } } | null;
    editTool: TrgToolEnum;
    scale: { pointScale: number; fontScale: number };
    onImageClick: (pos: { x: number; y: number }) => void;
    setScale: React.Dispatch<
        React.SetStateAction<{ pointScale: number; fontScale: number }>
    >;
    updateTrgPoint: (key: string, x: number, y: number) => void;
    updateRulerPoint: (key: 'm0' | 'm1', x: number, y: number) => void;
    isAILoading: boolean;
}

const TrgEditDisplay: React.FC<TrgEditDisplayProps> = ({
    currentState,
    trg,
    points,
    ruler,
    editTool,
    scale,
    onImageClick,
    setScale,
    updateTrgPoint,
    updateRulerPoint,
    isAILoading,
}) => {
    const [tempRuler, setTempRuler] = useState<{
        [p: string]: { x: number; y: number };
    } | null>(ruler);
    const [zoomScale, setZoomScale] = useState(1);
    const onClick = useCallback(
        (pos: { x: number; y: number }) => {
            onImageClick({ x: pos.x, y: pos.y });
            if (currentState === TRGState.SETMEASURE) {
                setTempRuler((prevState) => ({
                    ...(prevState ?? {}),
                    [ruler ? 'm1' : 'm0']: { x: pos.x, y: pos.y },
                }));
            }
        },
        [onImageClick]
    );

    const onPointDragStart = useCallback((e: KonvaEventObject<MouseEvent>) => {
        const stage = e.target.getStage();
        if (stage) {
            stage.container().style.cursor = 'move';
        }
    }, []);

    const onTrgPointDragEnd = useCallback(
        (e: KonvaEventObject<MouseEvent>, key: string) => {
            updateTrgPoint(key, e.target.x(), e.target.y());
        },
        []
    );

    const onRulerPointDragMove = useCallback(
        (e: KonvaEventObject<MouseEvent>, key: string) => {
            setTempRuler((prevState) => ({
                ...prevState,
                [key]: { x: e.target.x(), y: e.target.y() },
            }));
        },
        []
    );

    const onRulerPointDragEnd = useCallback(
        (e: KonvaEventObject<MouseEvent>, key: string) => {
            if (key === 'm1' || key === 'm0') {
                updateRulerPoint(key, e.target.x(), e.target.y());
                if (key === 'm1') {
                    setTempRuler((prevState) => ({
                        ...prevState,
                        m1: { x: e.target.x(), y: e.target.y() },
                    }));
                } else {
                    setTempRuler((prevState) => ({
                        ...prevState,
                        m0: { x: e.target.x(), y: e.target.y() },
                    }));
                }
            }
        },
        []
    );

    return (
        <div
            className={isAILoading ? 'animated-element' : ''}
            style={{
                flex: '1',
                backgroundColor: trg?.bgColor,
                cursor: editTool == TrgToolEnum.MOVE ? 'grab' : '',
            }}
        >
            <CanvasBaseKonva
                draggable={editTool == TrgToolEnum.MOVE}
                setZoomScale={setZoomScale}
            >
                <EditArea
                    className="add-trg__edit-area"
                    imageSrc={trg?.photo ?? ''}
                    onImageClick={onClick}
                    rescale={setScale}
                >
                    <Group>
                        {editTool &&
                            [
                                TrgToolEnum.POINT,
                                TrgToolEnum.MEASURE,
                                TrgToolEnum.MOVE,
                                TrgToolEnum.ZOOM,
                                TrgToolEnum.OUT,
                                TrgToolEnum.EDIT,
                            ].includes(editTool) &&
                            findPerpendiculars(
                                tempRuler?.m0,
                                tempRuler?.m1
                            ).map((points, index) => {
                                return (
                                    <Line
                                        key={index}
                                        stroke={
                                            index > 0 ? '#2A8BF280' : '#2A8BF2'
                                        }
                                        strokeWidth={
                                            index > 0
                                                ? 2 * scale.pointScale
                                                : (7 * scale.pointScale) /
                                                  zoomScale
                                        }
                                        points={points}
                                    />
                                );
                            })}
                        {editTool && (
                            <>
                                {[
                                    TrgToolEnum.POINT,
                                    TrgToolEnum.MEASURE,
                                    TrgToolEnum.MOVE,
                                    TrgToolEnum.ZOOM,
                                    TrgToolEnum.OUT,
                                    TrgToolEnum.EDIT,
                                ].includes(editTool) &&
                                    Object.entries(tempRuler ?? {}).map(
                                        ([key, value]) => (
                                            <TrgPoint
                                                key={key}
                                                draggable={
                                                    TrgToolEnum.MEASURE ===
                                                    editTool
                                                }
                                                display={true}
                                                radius={5}
                                                x={value.x}
                                                y={value.y}
                                                title={key}
                                                dragMove={onRulerPointDragMove}
                                                dragEnd={onRulerPointDragEnd}
                                                dragStart={onPointDragStart}
                                                scale={scale}
                                            />
                                        )
                                    )}
                                {[
                                    TrgToolEnum.POINT,
                                    TrgToolEnum.MOVE,
                                    TrgToolEnum.ZOOM,
                                    TrgToolEnum.OUT,
                                    TrgToolEnum.EDIT,
                                ].includes(editTool) &&
                                    points.map((item) => (
                                        <TrgPoint
                                            key={item.name}
                                            draggable={
                                                editTool === TrgToolEnum.POINT
                                            }
                                            radius={5}
                                            x={item.x}
                                            y={item.y}
                                            title={item.name}
                                            dragMove={() => {
                                                return;
                                            }}
                                            dragEnd={onTrgPointDragEnd}
                                            dragStart={onPointDragStart}
                                            scale={scale}
                                            display={item.is_active}
                                        />
                                    ))}
                            </>
                        )}
                    </Group>
                </EditArea>
            </CanvasBaseKonva>
        </div>
    );
};

export default TrgEditDisplay;
