import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styles from './AddPatientPage.module.scss';
import PatientSexField from '../pages/fields/common/PatientSexField';
import { Link } from 'react-router-dom';
import { ROUTES } from '@constants/routes';
import { uploadPatientAvatar } from '@utils/patients';
import { useHistory } from 'react-router';
import { UserPhotoUpload } from './userPhotoUpload/UserPhotoUpload';
import { doctorAPIs } from '@APIs/doctor.apis';
import { Sex } from '@models/sex.enum';
import { addAvatarUrl, pushPatient } from '@store/feature/patients.slice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import InputField from '@components/inputfield/InputField';
import { validators } from '@components/inputfield/helpers/validation';
import { toast } from 'react-toastify';
import CustomToastError from '@components/UI/CustomToastError/CustomToastError';
import Button from '@components/common/button/Button';

type AddPatientFormDataType = {
    email?: string;
    'patient-notify'?: boolean;
    surname: string;
    sex: Sex;
    name: string;
    middleName: string;
    birthday: Date;
};

const AddPatientPage = (): JSX.Element => {
    const history = useHistory();
    const [emailError, setEmailError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const [selectedFile, setSelectedFile] = useState<File>();
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<AddPatientFormDataType>({ mode: 'onChange' });

    const createPatient = async (formData: AddPatientFormDataType) => {
        setIsLoading(true);

        const birthday = new Date(formData?.birthday);

        const data = {
            sex: formData.sex,
            surname: formData.surname,
            name: formData.name,
            middleName: formData.middleName,
            birthday: birthday,
            // Пока мобилки нет для пациентов, сообщения на почту не отправляются
            'patient-notify': false,
            dateOfBirth: birthday?.getTime(),
        };

        if (formData?.email) {
            (data as unknown as { email: string })['email'] = formData.email;
        }

        doctorAPIs
            .createPatient(data)
            .then(({ data }) => {
                const returnedId = data.patient.id;
                dispatch(pushPatient(data.patient));

                selectedFile &&
                    uploadPatientAvatar(returnedId, selectedFile)
                        .then((response) => {
                            dispatch(
                                addAvatarUrl({
                                    id: returnedId,
                                    url: response.data.url,
                                })
                            );
                        })
                        .catch(() => toast.error('Не удалось загрузить фото'));

                history.replace(
                    ROUTES.PATIENTS.$(returnedId).PRESENTATIONS.END
                );
            })
            .catch((error) => {
                if (error.response.status == 405) {
                    toast.error(
                        <CustomToastError
                            text={
                                'Достигнут лимит по Пользователям в месяц, приобретите подписку'
                            }
                            buttonText={'Тарифы'}
                            onClick={() => history.push(ROUTES.PROFILE.END)}
                        />
                    );
                } else if (error.response) {
                    toast.error(error.response.data.message);

                    if (
                        error.response.status === 409 ||
                        error.response.status === 403
                    ) {
                        setEmailError(error.response.data.message);
                    }
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className={styles.addPatient__container}>
            <div className={styles.addPatient__header}>
                <div className={styles.addPatient__titleBlock}>
                    <Link
                        to={ROUTES.PATIENTS.END}
                        className={styles.addPatient__goBackIcon}
                    />
                    <p className={styles.addPatient__title}>
                        Новый пользователь
                    </p>
                </div>

                <Button
                    title="Создать"
                    isLoading={isLoading}
                    onClick={handleSubmit(createPatient)}
                />
            </div>
            <section className={styles.addPatient__bodyContainer}>
                <div className={styles.addPatient__body}>
                    <div className={styles.addPatient__userPhotoContainer}>
                        <UserPhotoUpload
                            setSelectedFile={setSelectedFile}
                            selectedPhotoUrl={selectedPhotoUrl}
                            setSelectedPhotoUrl={setSelectedPhotoUrl}
                        />
                    </div>
                    <div className={styles.addPatient__sexSwitchBlock}>
                        <Controller
                            name="sex"
                            control={control}
                            defaultValue={Sex.M}
                            render={({ field }) => (
                                <PatientSexField
                                    sex={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </div>
                    <form className={styles.addPatient__form}>
                        <div className={styles.addPatient__formBlock}>
                            <InputField
                                label="Email"
                                placeholder="Введите почту пациента"
                                fieldName="email"
                                type="EMAIL"
                                isRequired={false}
                                register={register('email', {
                                    ...validators['EMAIL'],
                                    required: false,
                                })}
                                errorText={errors.email?.message || emailError}
                                className={styles.addPatient__input}
                            />
                        </div>
                        <div className={styles.addPatient__formBlock}>
                            <InputField
                                label="Фамилия"
                                placeholder="Введите фамилию пациента"
                                fieldName="surname"
                                type="PLAINTEXT"
                                register={register(
                                    'surname',
                                    validators['NAME']
                                )}
                                errorText={errors.surname?.message}
                                className={styles.addPatient__input}
                            />
                            <InputField
                                label="Имя"
                                placeholder="Введите имя пациента"
                                fieldName="name"
                                type="PLAINTEXT"
                                register={register('name', validators['NAME'])}
                                errorText={errors.name?.message}
                                className={styles.addPatient__input}
                            />
                            <InputField
                                label="Отчество"
                                placeholder="Введите отчество пациента"
                                fieldName="middleName"
                                isRequired={false}
                                type="PLAINTEXT"
                                register={register('middleName', {
                                    ...validators['NAME'],
                                    required: false,
                                })}
                                errorText={errors.middleName?.message}
                                className={styles.addPatient__input}
                            />
                            <InputField
                                label="Дата рождения"
                                placeholder="Введите дату рождения"
                                fieldName="birthday"
                                type="DATE"
                                register={register(
                                    'birthday',
                                    validators['BIRTHDAY']
                                )}
                                errorText={errors.birthday?.message}
                                control={control}
                            />
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default AddPatientPage;
