import React from 'react';
import { LoadingNotification } from './Notifications/LoadingNotification';
import { ErrorNotification } from './Notifications/ErrorNotification';
import { SuccessNotification } from './Notifications/SuccessNotification';

const NotificationPage = (): JSX.Element => {
    return (
        <div
            style={{
                minHeight: '80%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                rowGap: '22px',
            }}
        >
            <LoadingNotification />
            <ErrorNotification />
            <SuccessNotification />
        </div>
    );
};

export default NotificationPage;
