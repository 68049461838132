import { GridColDef } from '@mui/x-data-grid';
import { constructLocalDate } from '@utils/constructLocalDate';
import TrgTableStatusColumn from '@components/ClientTrgTable/TrgTableStatusColumn';
import TrgTableTitleColumn from '@components/ClientTrgTable/TrgTableTitleColumn';
import TrgTableActionsColumn from '@components/ClientTrgTable/TrgTableActionsColumn';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { updateTrgTitle } from '@feature/trg.slice';
import { clearTrgImageParams } from '@feature/trg-state.slice';

export function ClientTrgTableColumns(): GridColDef[] {
    const dispatch = useAppDispatch();
    const onClick = () => {
        dispatch(clearTrgImageParams());
    };
    return [
        {
            field: 'state',
            headerName: 'Статус',
            width: 130,
            renderCell: (params) => TrgTableStatusColumn(params),
        },
        {
            field: 'title',
            headerName: 'Название',
            disableColumnMenu: true,
            width: 150,
            flex: 2,
            renderCell: (params) =>
                TrgTableTitleColumn({
                    ...params,
                    updatePresentationInState: (
                        id: string,
                        title: string,
                        updated_at: number
                    ) =>
                        dispatch(
                            updateTrgTitle({
                                id,
                                title,
                                updated_at,
                            })
                        ),
                }),
        },
        {
            field: 'date',
            headerName: 'Дата создания',
            disableColumnMenu: true,
            width: 150,
            flex: 1,
            renderCell: (params) => constructLocalDate(params.row.created_at),
            valueGetter: (params) => params.row.created_at,
            sortComparator: (a, b) => b - a,
        },
        {
            field: 'change',
            headerName: 'Действия',
            sortable: false,
            headerAlign: 'right',
            align: 'center',
            width: 120,
            disableColumnMenu: true,
            renderCell: (params) => TrgTableActionsColumn({ params, onClick }),
        },
    ];
}
