import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import React, { useEffect, useRef, useState } from 'react';
import { Stage } from 'react-konva';
import style from './CanvasBase.module.scss';
import { useAppSelector } from '@hooks/useAppSelector';
import { selectTrgEditTool, setTrgEditTool } from '@store/feature/tools.slice';
import { TrgToolEnum } from '@interfaces/tools.interface';
import { useAppDispatch } from '@hooks/useAppDispatch';

export function CanvasBaseKonva({
    draggable,
    children,
    setZoomScale,
}: {
    draggable: boolean;
    children?: JSX.Element | JSX.Element[] | null;
    setZoomScale: (zoom: number) => void;
}): JSX.Element {
    const dispatch = useAppDispatch();
    const selectedTool = useAppSelector(selectTrgEditTool);
    const [isZoomAnimationRunning, setIsZoomAnimationRunning] = useState(false);

    const setSelectedTool = (selectedTool: TrgToolEnum) => {
        dispatch(setTrgEditTool(selectedTool));
    };
    // Containers refs
    const container = useRef<HTMLDivElement>(null);
    const stage = useRef<Konva.Stage>(null);
    // Drag properties
    let isDraggable = false;
    let lastPointPosition = { x: 0, y: 0 };
    let lastPosition = { x: 0, y: 0 };

    // Full screen and fix size on resize
    useEffect(() => {
        function fix() {
            if (container.current) {
                stage.current?.size({
                    width: container.current.parentElement?.offsetWidth ?? 1,
                    height: container.current.parentElement?.offsetHeight ?? 1,
                });
            }
        }

        fix();

        window.addEventListener('resize', fix);
        return () => window.removeEventListener('resize', fix);
    }, []);

    useEffect(() => {
        if (
            selectedTool == TrgToolEnum.ZOOM ||
            selectedTool == TrgToolEnum.OUT
        ) {
            setSelectedTool(TrgToolEnum.POINT);
            if (!isZoomAnimationRunning) {
                setIsZoomAnimationRunning(true);
                const oldScale = stage.current?.scale()?.x ?? 1.0;
                const scaleFactor =
                    selectedTool === TrgToolEnum.ZOOM ? 1.2 : 0.8;
                const newScale = oldScale * scaleFactor;
                const target = stage.current;
                const pointer = {
                    x: (target?.width() ?? 0) / 2 ?? 0,
                    y: (target?.height() ?? 0) / 2 ?? 0,
                };

                const mousePointTo = {
                    x: (pointer.x - (target?.x() ?? 0)) / oldScale,
                    y: (pointer.y - (target?.y() ?? 0)) / oldScale,
                };

                const newPos = {
                    x: pointer.x - mousePointTo.x * newScale,
                    y: pointer.y - mousePointTo.y * newScale,
                };
                const anim = new Konva.Animation((frame) => {
                    if (frame && target) {
                        const scaleDiff = newScale - target.scaleX();
                        const xDiff = newPos.x - target.x();
                        const yDiff = newPos.y - target.y();
                        target.scaleX(
                            target.scaleX() + (scaleDiff * frame.timeDiff) / 200
                        );
                        target.scaleY(
                            target.scaleY() + (scaleDiff * frame.timeDiff) / 200
                        );
                        target.x(target.x() + (xDiff * frame.timeDiff) / 200);
                        target.y(target.y() + (yDiff * frame.timeDiff) / 200);
                        if (
                            Math.abs(scaleDiff) < 0.01 &&
                            Math.abs(xDiff) < 10 &&
                            Math.abs(yDiff) < 10
                        ) {
                            anim.stop();
                            setIsZoomAnimationRunning(false);
                        }
                    }
                }, stage.current?.getLayer());
                anim.start();
            }
        }
    }, [selectedTool, stage]);

    const zoom = (e: KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();

        const scaleFactor = 1.08;
        const delta = e.evt.deltaY
            ? e.evt.deltaY / 40
            : e.evt.detail
            ? -e.evt.detail
            : 0;
        const factor = Math.pow(scaleFactor, delta);

        const target = stage.current;
        const oldScale = stage.current?.scaleX() ?? 1;
        const pointer = stage.current?.getPointerPosition() ?? { x: 0, y: 0 };

        const mousePointTo = {
            x: (pointer.x - (target?.x() ?? 0)) / oldScale,
            y: (pointer.y - (target?.y() ?? 0)) / oldScale,
        };

        let direction = e.evt.deltaY > 0 ? 1 : -1;

        if (e.evt.ctrlKey) {
            direction = -direction;
        }

        const newScale = oldScale / factor;

        stage.current?.scale({ x: newScale, y: newScale });
        setZoomScale(newScale);

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };
        stage.current?.position(newPos);
    };

    const move = (e: KonvaEventObject<WheelEvent>) => {
        const delta = { x: -e.evt.deltaX * 0.5, y: -e.evt.deltaY * 0.5 };
        const position = stage.current?.position() ?? { x: 0, y: 0 };

        stage.current?.position({
            x: position.x + delta.x,
            y: position.y + delta.y,
        });

        e.evt.preventDefault();
    };

    const onWheel = (e: KonvaEventObject<WheelEvent>) => {
        if (selectedTool === TrgToolEnum.MOVE || e.evt.ctrlKey) {
            zoom(e);
            return;
        }

        move(e);
    };

    // Drag mouse
    const onMouseUp = () => {
        isDraggable = false;
    };
    const onMouseDown = (e: KonvaEventObject<MouseEvent>) => {
        if (!draggable) return;

        isDraggable = true;

        lastPointPosition = stage.current?.getPointerPosition() ?? {
            x: 0,
            y: 0,
        };
        lastPosition = stage.current?.position() ?? { x: 0, y: 0 };
    };

    const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        if (!draggable) return;

        if (isDraggable) {
            const pointPosition = stage.current?.getPointerPosition() ?? {
                x: 0,
                y: 0,
            };

            stage.current?.position({
                x: lastPosition.x + (pointPosition.x - lastPointPosition.x),
                y: lastPosition.y + (pointPosition.y - lastPointPosition.y),
            });
        }
    };

    return (
        <div ref={container} className={style.Canvas}>
            <Stage
                draggable
                width={100}
                height={100}
                ref={stage}
                onWheel={onWheel}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseMove={onMouseMove}
            >
                {children}
            </Stage>
        </div>
    );
}
