import React, { useEffect, useRef, useState } from 'react';
import styles from './TrgReportPdf.module.scss';
import logo from '@images/logo.svg';
import { ITrgReportValue } from '@interfaces/trg-report.interface';
import TableRow from './TableRow/TableRow';
import { Stage, Layer, Image as KImage } from 'react-konva';
import { TrgReportPoint } from '@components/TrgReport/common/report-point/trg-report-point';
import { id } from '@utils/utils';
import Konva from 'konva';
import { Charts } from '@components/TrgReport/Charts/Charts';
import { useReactToPrint } from 'react-to-print';

const TrgReportPdf = ({
    isPrinting,
    setIsPrinting,
    results,
    img,
    points,
    isImgShown,
    areChartsShown,
    conclusion,
    charts,
    doctorFullName,
    patientFullName,
    fileName,
}: {
    isPrinting: boolean;
    setIsPrinting: (is: boolean) => void;
    results: ITrgReportValue[];
    img: CanvasImageSource | undefined;
    points: {
        [key: string]: { x: number; y: number };
    };
    isImgShown: boolean;
    areChartsShown: boolean;
    conclusion?: string;
    charts: [
        number,
        number,
        number,
        {
            method: string;
            status: string;
            value: [
                {
                    value: number;
                },
                {
                    value: number;
                }
            ];
        },
        number | string
    ][];
    doctorFullName: string;
    patientFullName: string;
    fileName: string;
}) => {
    const reportRef = useRef<HTMLTableElement>(null);

    const date = new Date().toLocaleString('ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
    const [imgScale, setImgScale] = useState<number>(0);

    useEffect(() => {
        if (img) {
            setImgScale((areChartsShown ? 200 : 300) / Number(img.width));
        }
    }, [img, areChartsShown]);

    const stage = useRef<Konva.Stage>(null);

    const handlePrint = useReactToPrint({
        copyStyles: true,
        onBeforeGetContent: () => {
            document.title = fileName;
            return Promise.resolve();
        },
    });

    useEffect(() => {
        if (isPrinting && imgScale > 0) {
            setTimeout(
                () =>
                    handlePrint(null, () =>
                        isPrinting ? reportRef.current : null
                    ),
                1000
            );
            setTimeout(() => setIsPrinting(false), 2000);
        }
    }, [isPrinting, imgScale]);

    return isPrinting ? (
        <div ref={reportRef} className={styles.reportContainer}>
            <img
                src={logo}
                alt={'logo'}
                className={styles.reportContainer__logo}
            />
            <div className={styles.reportContainer__header}>
                <div className={styles.reportContainer__leftHeaderBlock}>
                    <span className={styles.reportContainer__headerText}>
                        Врач:{' '}
                        <span
                            className={styles.reportContainer__headerText_bold}
                        >
                            {doctorFullName}
                        </span>
                    </span>
                    <span className={styles.reportContainer__headerText}>
                        Пациент:{' '}
                        <span
                            className={styles.reportContainer__headerText_bold}
                        >
                            {patientFullName}
                        </span>
                    </span>
                </div>
                <div>
                    <span className={styles.reportContainer__headerText}>
                        Дата отчета:{' '}
                        <span
                            className={styles.reportContainer__headerText_bold}
                        >
                            {date}
                        </span>
                    </span>
                </div>
            </div>
            <div className={styles.reportContainer__reportBlock}>
                <div className={styles.table__container}>
                    <table className={styles.table}>
                        <thead className={styles.table__header}>
                            <tr>
                                <th
                                    className={
                                        styles.table__headerCell_withoutPadding
                                    }
                                >
                                    Измерение
                                </th>
                                <th className={styles.table__headerCell}>
                                    Результат (норма)
                                </th>
                                {areChartsShown && (
                                    <th
                                        className={
                                            styles.table__headerCell_forCharts
                                        }
                                    >
                                        График
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody className={styles.table__body}>
                            <tr className={styles.table__firstRow}>
                                {areChartsShown && (
                                    <>
                                        <td
                                            className={styles.table__emptyCell}
                                            colSpan={2}
                                        ></td>
                                        <td rowSpan={results.length + 1}>
                                            <Charts
                                                viewBox={`0 -25 ${150} ${
                                                    charts.length * 50 + 26
                                                }`}
                                                height={results.length * 50}
                                                values={charts}
                                                width={130}
                                                chartOptions={{
                                                    height: 51,
                                                    width: 130,
                                                }}
                                            />
                                        </td>
                                    </>
                                )}
                            </tr>

                            {results.map((result) => (
                                <TableRow
                                    key={id()}
                                    title={result.title}
                                    value={result.value}
                                    measurement={result.measurement}
                                    normal={result.norm.value}
                                    description={result.message}
                                    method={result.norm.method}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
                <div
                    className={styles.reportContainer__imgAndConclusionBlock}
                    style={{
                        width: areChartsShown ? '200px' : '300px',
                    }}
                >
                    {isImgShown && (
                        <div className={styles.reportContainer__imgContainer}>
                            <Stage
                                ref={stage}
                                width={areChartsShown ? 200 : 300}
                                height={
                                    img
                                        ? Number(img.height) * imgScale
                                        : areChartsShown
                                        ? 200
                                        : 300
                                }
                                scaleX={imgScale}
                                scaleY={imgScale}
                            >
                                <Layer>
                                    <KImage image={img} />
                                    {Object.entries(points).map(
                                        ([key, { x, y }]) => (
                                            <TrgReportPoint
                                                key={key}
                                                x={x}
                                                y={y}
                                                radius={1}
                                                title={key}
                                            />
                                        )
                                    )}
                                </Layer>
                            </Stage>
                        </div>
                    )}
                    <div className={styles.reportContainer__conclusionBlock}>
                        <span>Вывод</span>
                        <div
                            className={
                                styles.reportContainer__conclusionTextContainer
                            }
                        >
                            <p>{conclusion}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div></div>
    );
};
export default TrgReportPdf;
