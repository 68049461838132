import { ROUTES } from '@constants/routes';
import { IUserProfile } from '@interfaces/authentication';
import React from 'react';
import { NavLink } from 'react-router-dom';
import MenuItem from '../menuitem/MenuItem';
import MenuUserItem from '../menuuser/MenuUser';
import style from './MenuItems.module.scss';

function MenuItems({
    profile,
    profilePicture,
}: {
    profile: IUserProfile;
    profilePicture: string;
}): JSX.Element {
    return (
        <div className={style.menu__items}>
            <NavLink to={ROUTES.PROFILE.END}>
                <MenuUserItem
                    name={profile.name}
                    surname={profile.surname}
                    image={profilePicture}
                    position={profile.position}
                    purchase={profile.purchase?.status}
                />
            </NavLink>
            <NavLink
                to={ROUTES.PATIENTS.END}
                style={{ textDecoration: 'none' }}
                activeClassName="menu__item_active"
            >
                <MenuItem image={'patients'} title={'Пациенты'} active={true} />
            </NavLink>

            <div className={style.menu__itemSeparator_mini} />
            <NavLink
                to={ROUTES.PRESENTATIONS.END}
                style={{ textDecoration: 'none' }}
                activeClassName="menu__item_active"
            >
                <MenuItem
                    image={'presentations'}
                    title={'Презентации'}
                    active={true}
                />
            </NavLink>
            <div className={style.menu__itemSeparator_mini} />
            <NavLink
                to={ROUTES.TRG.END}
                style={{ textDecoration: 'none' }}
                activeClassName="menu__item_active"
            >
                <MenuItem image={'trg'} title={'Расчеты'} active={true} />
            </NavLink>
            <div className={style.menu__itemSeparator_mini} />
            <MenuItem image={'document'} title={'Документы'} active={false} />
            <div className={style.menu__itemSeparator_mini} />
            <MenuItem image={'task'} title={'Задачи'} active={false} />
        </div>
    );
}

export const MenuItemsMemo = React.memo(MenuItems);
