import React from 'react';
import DownArrowSvg from '@images/chevron.down.svg';
import './custom-select.scss';
import { toast } from 'react-toastify';
import { classes } from '@utils/classNames';

type CustomSelectProps = {
    title: string;
    onSelect: (v: string) => void;
    selectedItemTitle?: string;
    isActive: boolean;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    items: { id: string; label: string }[];
};

export default function CustomSelect({
    title,
    onSelect,
    selectedItemTitle,
    isActive,
    isOpen,
    setIsOpen,
    items,
}: CustomSelectProps): JSX.Element {
    const handleSelect = (id: string) => {
        onSelect(id);
        setIsOpen(false);
    };

    return (
        <div
            className={classes('custom-select', {
                ['custom-select_disabled']: !isActive,
            })}
        >
            <div
                className={`custom-select__head ${
                    isOpen ? 'custom-select_open' : ''
                }`}
                onClick={() =>
                    isActive
                        ? setIsOpen((prev) => !prev)
                        : toast.warning(
                              'Установите две точки m0 и m1 так, чтобы между ними было 10 мм'
                          )
                }
            >
                <div className="custom-select__title">
                    {selectedItemTitle ?? title}
                </div>
                <img
                    alt="arrow"
                    className="custom-select__arrow"
                    src={DownArrowSvg}
                />
            </div>
            {isOpen && (
                <div className="custom-select__body">
                    {items.map((item) => (
                        <div
                            key={item.id}
                            className="custom-select__option"
                            onClick={() => handleSelect(item.id)}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
