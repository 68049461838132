import React, { useRef } from 'react';
import RangeSlider from './common/rangeSlider/RangeSlider';
import './Editor.scss';

import {
    clearTrgImageParams,
    getBrightness,
    getContrast,
    getIsInverted,
    setTrgImageBrightness,
    setTrgImageContrast,
    setTrgImageIsInvert,
} from '@store/feature/trg-state.slice';
import Checkbox from './common/checkbox/Checkbox';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';

const Editor = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const formRef = useRef<HTMLFormElement>(null);
    const brightness = useAppSelector(getBrightness);
    const contrast = useAppSelector(getContrast);
    const isInverted = useAppSelector(getIsInverted);

    return (
        <div className="trg-editor" style={{ top: 42, left: 0 }}>
            <form className="trg-editor__tools" ref={formRef}>
                <div className="tool">
                    <RangeSlider
                        setValue={(value) =>
                            dispatch(setTrgImageBrightness(value))
                        }
                        value={brightness}
                    >
                        Яркость:
                    </RangeSlider>
                </div>
                <div className="tool">
                    <RangeSlider
                        setValue={(value) =>
                            dispatch(setTrgImageContrast(value))
                        }
                        value={contrast}
                    >
                        Контрастность:
                    </RangeSlider>
                </div>
                <div className="tool__buttons">
                    <div className="tool">
                        <Checkbox
                            setValue={(value) =>
                                dispatch(setTrgImageIsInvert(value))
                            }
                            value={isInverted}
                        >
                            Инвертировать
                        </Checkbox>
                    </div>
                    <div
                        className="tool tool_text"
                        onClick={() => {
                            dispatch(clearTrgImageParams());
                            if (
                                formRef.current &&
                                (isInverted ||
                                    contrast !== 0 ||
                                    brightness !== 0)
                            ) {
                                formRef.current.reset();
                            }
                        }}
                    >
                        По умолчанию
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Editor;
