import React from 'react';
import { useAuth } from '@contexts/AuthContext';
import styles from './Logout.module.scss';

const LogoutLink = (): JSX.Element => {
    const { setJWT } = useAuth();

    const logout = () => {
        window.localStorage.removeItem('token');
        setJWT(undefined);
    };
    return (
        <div onClick={() => logout()} className={styles.container}>
            <span className={styles.link}>Выйти</span>
        </div>
    );
};

export default LogoutLink;
