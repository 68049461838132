import React, { useState } from 'react';
import style from './TableEditInput.module.scss';
import { ReactComponent as AcceptIcon } from '@images/acceptInputIcon.svg';

const TableEditInput = ({
    value,
    onAcceptIconClick,
}: {
    value: string;
    onAcceptIconClick: (title: string) => void;
}) => {
    const [inputValue, setInputValue] = useState(value);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleIconClick = () => {
        if (value !== inputValue) {
            onAcceptIconClick(inputValue);
        }
    };

    return (
        <div className={style.tableEditInput}>
            <input
                className={style.tableEditInput__input}
                value={inputValue}
                onChange={handleInputChange}
                type={'text'}
            />
            <div className={style.tableEditInput__iconContainer}>
                <AcceptIcon
                    className={style.tableEditInput__icon}
                    onMouseDown={handleIconClick}
                />
            </div>
        </div>
    );
};

export default TableEditInput;
