import React from 'react';
import LoginPageBody from './body/LoginPageBody';
import humanPhoto from '@assets/images/loginHuman.png';
import style from './LoginPage.module.scss';

const LoginPage = (): JSX.Element => (
    <div className={style.loginPage}>
        <div className={style.loginPage__bodyContainer}>
            <LoginPageBody />
        </div>
        <div className={style.loginPage__promoContainer}>
            <div className={style.loginPage__promoBlock}>
                <span className={style.loginPage__promoTitle}>
                    dilab – опыт стоматологов, преобразованный в инновации
                </span>
                <span className={style.loginPage__promoSubtitle}>
                    Будьте на шаг впереди с dilab
                </span>
                <img
                    src={humanPhoto}
                    alt="human"
                    className={style.loginPage__img}
                />
            </div>
        </div>
    </div>
);

export default LoginPage;
