import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { releaseNotesAPIs } from '@APIs/releaseNotes.apis';
import { IReleaseNote } from '@interfaces/IReleaseNotes';

type TUseReleaseNotes = () => {
    isWhatsNewModalOpen: boolean;
    setIsWhatsNewModalOpen: Dispatch<SetStateAction<boolean>>;
    releaseNotesData?: IReleaseNote;
};

export const useReleaseNotes: TUseReleaseNotes = () => {
    const [isWhatsNewModalOpen, setIsWhatsNewModalOpen] = useState(false);
    const [releaseNotesData, setReleaseNotesData] = useState<IReleaseNote>();

    useEffect(() => {
        const fetchReleaseNotes = async () => {
            try {
                const { data } = await releaseNotesAPIs.getReleaseNotes();

                setIsWhatsNewModalOpen(!data?.is_viewed || false);
                setReleaseNotesData(data);
            } catch {
                setIsWhatsNewModalOpen(false);
            }
        };

        fetchReleaseNotes();
    }, []);

    return { isWhatsNewModalOpen, setIsWhatsNewModalOpen, releaseNotesData };
};
