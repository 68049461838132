import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import style from './ClientTrgTable.module.scss';
import PlusIcon from '@assets/images/addCircle.svg';
import { ROUTES } from '@constants/routes';

import { DataGrid, ruRU } from '@mui/x-data-grid';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';

import { TrgAPIs } from '@APIs/trg.apis';

import { useHistory, useParams } from 'react-router';
import { ClientTrgTableColumns } from './ClientTrgTableColumns';
import { useAppSelector } from '@hooks/useAppSelector';
import {
    selectSelectedPatient,
    setSelectedPatient,
} from '@store/feature/patient.slice';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';

import { asyncLoadTrgList, selectTrgList } from '@store/feature/trg.slice';
import { ITrg } from '@interfaces/trg.interface';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { selectPatients } from '@store/feature/patients.slice';
import { toast } from 'react-toastify';
import { toggleTriggerState } from '@feature/trigger__scale-points.slice';
import { resetCurrentState } from '@feature/trg__current-method.slice';
import CustomToastError from '@components/UI/CustomToastError/CustomToastError';

const useStyles = makeStyles({
    root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
            overflow: 'inherit',
        },
        '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
            backgroundColor: '#b2b2b214',
        },
        '&.MuiDataGrid-columnSeparator--sideLeft': {
            display: 'none',
        },

        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
            {
                outline: 'none',
            },
        '& .MuiDataGrid-columnHeaders': {
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            color: '#000000',
        },
        '& .MuiDataGrid-columnHeaderRow': {
            paddingRight: '16px',
        },
    },
});

export const ClientTrgTable = (): JSX.Element => {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const trg_list = useAppSelector(selectTrgList);
    const selectedPatient = useAppSelector(selectSelectedPatient);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [is_img_loading, setIsImgLoading] = useState(false);
    const [filtered_trg, setFilteredTrg] = useState<ITrg[]>([]);
    const { patientId } = useParams<{ patientId: string }>();
    const patients = useAppSelector(selectPatients);

    const onAddButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        anchorRef.current = event.currentTarget;
        setIsMenuOpen(true);
    };

    const onCloseMenu = () => {
        setIsMenuOpen(false);
        anchorRef.current = null;
    };

    useEffect(() => {
        dispatch(
            setSelectedPatient(
                patients.find((patient) => patient?.id === patientId) ?? null
            )
        );
    }, [dispatch, patientId, patients]);

    useEffect(() => {
        if (patientId) {
            setIsLoading(true);
            const filteredByPatientId = trg_list.filter(
                (currentTrg) => currentTrg.patient?.id === patientId
            );
            setFilteredTrg(filteredByPatientId);
        }
    }, [trg_list]);

    useEffect(() => {
        setIsLoading(false);
        dispatch(resetCurrentState());
    }, [filtered_trg]);

    function sortByDateComparator(first_trg: ITrg, second_trg: ITrg) {
        return second_trg.created_at - first_trg.created_at;
    }

    const onFileInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const files = e.target.files;
            if (files?.length && patientId) {
                const data = new FormData();
                const newFileName = encodeURIComponent(files[0].name);
                setIsMenuOpen(false);
                data.append('trg-photo', files[0], newFileName);
                setIsImgLoading(true);
                TrgAPIs.create(data, patientId)
                    .then((res) => {
                        dispatch(asyncLoadTrgList());
                        dispatch(toggleTriggerState(false));
                        setIsImgLoading(false);
                        history.push(ROUTES.TRG.$(res.data.id).EDIT.END);
                    })
                    .catch((error) => {
                        if (error.response.status == 405) {
                            toast.error(
                                <CustomToastError
                                    text={
                                        'Достигнут лимит по ТРГ в месяц, приобретите подписку'
                                    }
                                    buttonText={'Тарифы'}
                                    onClick={() =>
                                        history.push(ROUTES.PROFILE.END)
                                    }
                                />
                            );
                        } else if (error.response) {
                            toast.error(error.response.data.message);
                        }
                        setIsImgLoading(false);
                    });
            }
        },
        [selectedPatient]
    );

    return (
        <div className={style.clientTrg}>
            {is_img_loading && <CustomLoader />}
            <div
                className={`${style.clientTrg__container} ${
                    is_img_loading ? style.hidden : ''
                }`}
            >
                <div className={style.clientTrg__header}>
                    <p className={style.clientTrg__title}>Расчеты</p>
                    <button
                        className={style.clientTrg__addButton}
                        onClick={onAddButtonClick}
                    >
                        <img
                            className={style.clientTrg__addButtonIcon}
                            src={PlusIcon}
                            alt="plus icon"
                        />
                    </button>
                    <Menu
                        id="create-trg-button"
                        aria-labelledby="create-trg-button"
                        anchorEl={anchorRef.current}
                        getContentAnchorEl={null}
                        open={isMenuOpen}
                        onClose={onCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        elevation={0}
                        PaperProps={{ className: style.clientTrg__menu }}
                        transitionDuration={300}
                    >
                        <MenuItem className={style.clientTrg__menuItem} key={1}>
                            <label>
                                <input
                                    type="file"
                                    title="Добавить"
                                    style={{ display: 'none' }}
                                    accept={'image/png, image/jpeg'}
                                    onChange={onFileInputChange}
                                />
                                <p>Расчёт ТРГ</p>
                            </label>
                        </MenuItem>
                    </Menu>
                </div>
                <div className={style.clientTrg__tableContainer}>
                    <DataGrid
                        className={classes.root}
                        columns={ClientTrgTableColumns()}
                        rows={[...filtered_trg].sort(sortByDateComparator)}
                        disableColumnMenu={true}
                        hideFooterSelectedRowCount={true}
                        disableSelectionOnClick={true}
                        loading={isLoading}
                        getRowId={(row) => row.id}
                        localeText={
                            ruRU.components.MuiDataGrid.defaultProps.localeText
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default ClientTrgTable;
