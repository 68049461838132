import React from 'react';
import styles from './Notification.module.scss';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';

export const LoadingNotification = (): JSX.Element => {
    return (
        <div className={styles.container}>
            <p>
                Переводим на страницу оплаты, <br /> ожидайте
            </p>
            <CustomLoader size={24} />
        </div>
    );
};
