import { ITrgReportValue } from '@interfaces/trg-report.interface';
import React, { useEffect } from 'react';
import { id } from '@utils/utils';
import { Charts } from '@components/TrgReport/Charts/Charts';
import styles from './ResultTableBody.module.scss';
import { classes } from '@utils/classNames';
import { getMeasurementSymbol } from '@utils/trgReportUtils';

function ResultTableBody({
    charts,
    results,
    charts_size,
    areChartsShown,
}: {
    charts: [
        number,
        number,
        number,
        {
            method: string;
            status: string;
            value: [
                {
                    value: number;
                },
                {
                    value: number;
                }
            ];
        },
        number | string
    ][];
    results: ITrgReportValue[];
    charts_size: {
        width: number;
        height: number;
    };
    areChartsShown: boolean;
}) {
    return (
        <tbody className={styles.table}>
            <tr className={styles.table__header}>
                <td className={styles.table__reportsBlock}>
                    <div
                        className={classes(
                            styles.tableRow,
                            styles.tableHeaderRow
                        )}
                    >
                        <div
                            className={classes(
                                styles.tableColumn,
                                styles.tableMeasurementColumn
                            )}
                        >
                            Измерение
                        </div>

                        <div
                            className={classes(
                                styles.tableColumn,
                                styles.tableNormColumn
                            )}
                        >
                            Норма
                        </div>
                        <div
                            className={classes(
                                styles.tableColumn,
                                styles.tableResultColumn
                            )}
                        >
                            Результат
                        </div>
                        <div
                            className={classes(
                                styles.tableColumn,
                                styles.tableInterpretationColumn
                            )}
                        >
                            Интерпретация результатов
                        </div>
                    </div>
                </td>
                {areChartsShown && (
                    <td className={styles.table__chartsBlockHeader}></td>
                )}
            </tr>
            <tr className={classes(styles.table__body, styles.noPrint)}>
                <td className={styles.table__reportsBlock}>
                    {results.map((result) => {
                        return (
                            <div
                                key={id()}
                                className={classes(
                                    styles.tableRow,
                                    styles.tableBodyRow
                                )}
                            >
                                <div
                                    className={classes(
                                        styles.tableColumn,
                                        styles.tableMeasurementColumn
                                    )}
                                >
                                    {result.measurement === 'DEGREE' ? (
                                        <>&ang;</>
                                    ) : (
                                        ''
                                    )}
                                    {result.title}
                                </div>
                                <div
                                    className={classes(
                                        styles.tableColumn,
                                        styles.tableNormColumn
                                    )}
                                >
                                    {result.min === 0 && result.max === 1000 ? (
                                        <span>-</span>
                                    ) : (
                                        <span>
                                            {result.norm.method === 'EQUAL' ? (
                                                <>
                                                    {parseFloat(
                                                        result.norm.value[0].value.toFixed(
                                                            1
                                                        )
                                                    )}
                                                    {getMeasurementSymbol(
                                                        result.measurement
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {parseFloat(
                                                        result.norm.value[0].value.toFixed(
                                                            1
                                                        )
                                                    )}
                                                    {' - '}
                                                    {parseFloat(
                                                        result.norm.value[1].value.toFixed(
                                                            1
                                                        )
                                                    )}
                                                    {getMeasurementSymbol(
                                                        result.measurement
                                                    )}
                                                </>
                                            )}
                                        </span>
                                    )}
                                </div>
                                <div
                                    className={classes(
                                        styles.tableColumn,
                                        styles.tableResultColumn
                                    )}
                                >
                                    <span>
                                        {parseFloat(result.value?.toFixed(1))}
                                        {getMeasurementSymbol(
                                            result.measurement
                                        )}
                                    </span>
                                </div>
                                <div
                                    className={classes(
                                        styles.tableColumn,
                                        styles.tableInterpretationColumn
                                    )}
                                >
                                    <span>{result.message}</span>
                                </div>
                            </div>
                        );
                    })}
                </td>
                {areChartsShown && (
                    <td className={styles.table__chartsBlock}>
                        <Charts
                            viewBox={`-20 -28 ${charts_size.width} ${
                                charts.length * 88
                            }`}
                            height={charts_size.height}
                            values={charts}
                            width={charts_size.width}
                            chartOptions={{
                                height: 56,
                                width: 250,
                            }}
                        />
                    </td>
                )}
            </tr>
        </tbody>
    );
}

export default ResultTableBody;
