import { Link } from 'react-router-dom';
import style from './LoginPageBody.module.scss';
import React, { useEffect, useState } from 'react';
import { ROUTES } from '@constants/routes';
import { useForm } from 'react-hook-form';
import { useAuth } from '@contexts/AuthContext';
import { useHistory } from 'react-router';
import { AuthorizationApis } from '@APIs/auth.apis';
import InputField from '@components/inputfield/InputField';
import { validators } from '@components/inputfield/helpers/validation';
import logo from '@images/logo.svg';
import companyLogo from '@images/companyLogo.svg';
import Button, { ButtonState } from '@components/common/button/Button';
import { AxiosResponse } from 'axios';

type FormData = {
    email: string;
    password: string;
};

const LoginPageBody = (): JSX.Element => {
    const [requestError, setRequestError] = useState<string>('');
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<FormData>({ mode: 'onChange' });
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const { isAuthenticated, setJWT } = useAuth();

    const handleSuccessResponse = (res: AxiosResponse<any>) => {
        if (res.data.roles[0] === 'DOCTOR') {
            window.localStorage.setItem('token', res.data.token);
            setRequestError('');
            setJWT(res.data.token);
            history.push(ROUTES.PATIENTS.END);
        } else if (res.data.roles[0] === 'USER') {
            setRequestError(
                'Данная система доступна только для докторов, скачайте мобильное приложение'
            );
        }
    };

    const handleErrorResponse = (status: number) => {
        if (status === 404) {
            setError(
                'email',
                { message: 'Данная почта не существует' },
                { shouldFocus: true }
            );
        } else {
            setRequestError('Неправильный логин или пароль.');
        }
    };

    const onSubmit = (data: FormData) => {
        setIsLoading(true);
        setRequestError('');

        AuthorizationApis.getJWT(data.email, data.password)
            .then((res) => {
                if ([200, 204, 304].includes(res.status)) {
                    handleSuccessResponse(res);
                }
            })
            .catch((error) => {
                if (error.response) {
                    handleErrorResponse(error.response.status);
                } else {
                    setRequestError('Произошла неизвестная ошибка');
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (isAuthenticated) history.push(ROUTES.PATIENTS.END);
    }, [isAuthenticated, history]);

    return (
        <div className={style.loginPageBody}>
            <div className={style.loginPageBody__header}>
                <img
                    className={style.loginPageBody__img}
                    alt="menu logo"
                    src={logo}
                />
            </div>
            <div className={style.loginPageBody__body}>
                <span className={style.loginPageBody__title}>Вход 👋</span>
                <div className={style.loginPageBody__formContainer}>
                    <form
                        className={style.loginPageBody__form}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <InputField
                            label="Email"
                            placeholder="Введите свой Email"
                            fieldName="email"
                            type="EMAIL"
                            isDisabled={false}
                            isRequired={false}
                            errorText={errors.email?.message}
                            register={register('email', validators['EMAIL'])}
                            className={style.loginPageBody__inputContainer}
                        />
                        <InputField
                            label="Пароль"
                            placeholder="Введите свой пароль"
                            fieldName="password"
                            type="PASSWORD"
                            isDisabled={false}
                            isRequired={false}
                            helpText="Забыли свой пароль?"
                            helpUrl={ROUTES.RESET.END}
                            register={register(
                                'password',
                                validators['PASSWORD']
                            )}
                            errorText={errors.password?.message}
                            className={style.loginPageBody__inputContainer}
                        />
                        <div className={style.loginPageBody__divider}></div>

                        <Button
                            title="Войти"
                            state={ButtonState.ACTIVE}
                            extraStyle={{
                                width: '100%',
                            }}
                            isLoading={isLoading}
                            error={requestError}
                            type="submit"
                        />
                    </form>
                    <p className={style.loginPageBody__privacyPolicyText}>
                        Нажимая, вы соглашаетесь с
                        <a
                            target="_blank"
                            href="https://dilab.ru/policy/"
                            rel="noreferrer noopener"
                            className={
                                style.loginPageBody__privacyPolicyText_accent
                            }
                        >
                            {' '}
                            политикой <br /> конфиденциальности
                        </a>
                    </p>
                </div>
                <div className={style.loginPageBody__createAccountBlock}>
                    <span className={style.loginPageBody__createAccountText}>
                        Нет аккаунта?
                    </span>
                    <Link
                        to={ROUTES.SIGN_UP.END}
                        style={{ textDecoration: 'none' }}
                    >
                        <div
                            className={style.loginPageBody__createAccountButton}
                        >
                            <span>Создать</span>
                        </div>
                    </Link>
                </div>
            </div>
            <div className={style.loginPageBody__footer}>
                <a
                    target="_blank"
                    href="https://vibelab.ru"
                    rel="noreferrer noopener"
                    className={style.loginPageBody__footerLink}
                >
                    <span className={style.loginPageBody__footerText}>
                        Разработано на волне с
                    </span>
                    <img src={companyLogo} alt={'company logo'} />
                </a>
            </div>
        </div>
    );
};

export default LoginPageBody;
