import React, { useMemo, useRef } from 'react';
import './Checkbox.scss';

const Checkbox = ({
    children,
    setValue,
    value,
}: {
    children: JSX.Element | string | undefined;
    setValue: (value: boolean) => void;
    value: boolean;
}): JSX.Element => {
    const checkboxRef = useRef<HTMLInputElement>(null);

    const onChange = () => {
        const checked = Boolean(checkboxRef.current?.checked);
        setValue(checked);
    };

    const id = useMemo(() => Math.random(), []);

    return (
        <div className="trg-checkbox">
            <input
                ref={checkboxRef}
                checked={value}
                name={'checkbox'}
                type="checkbox"
                className="trg-checkbox__input"
                id={`trg-checkbox-${id}`}
                onChange={onChange}
                defaultChecked={value ?? false}
            />
            <label htmlFor={`trg-checkbox-${id}`}>{children}</label>
        </div>
    );
};

export default Checkbox;
