import React, { useCallback, useEffect, useState } from 'react';
import './HomePage.scss';
import PresentationEditList from '@pages/PresentationEdit/components/PresentationEditList/PresentationEditList';
import PresentationEditPage from '@pages/PresentationEdit/PresentationEditPage';
import { useHistory, useParams } from 'react-router';
import { presentationAPIs } from '@APIs/presentation.apis';
import styles from '@components/store/PresentationStorePage.module.scss';
import { Alert } from '@components/common/alert/Alert';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';
import Button, { ButtonState } from '@components/common/button/Button';
import { doctorAPIs } from '@APIs/doctor.apis';
import Tutorial from '@components/common/Tutorial/Tutorial';
import { IPage, IPresentation } from '@interfaces/presentation.interface';
import FontLoader from '@utils/FontLoader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ROUTES } from '@constants/routes';
import {
    IArrow,
    ILine,
    IRectangle,
} from '@interfaces/presentationEditing.interface';

const HomePage = (): JSX.Element => {
    const [pages, setPages] = useState<IPage[]>([]);
    const [selectedPage, setSelectedPage] = useState<IPage | null>(null);
    const [presentation, setPresentation] = useState<IPresentation | null>(
        null
    );
    const [isPresentationLoaded, setIsPresentationLoaded] = useState(false);
    const [selectedPageIndex, setSelectedPageIndex] = useState(0);
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [stageSize, setStageSize] = useState<{
        width: number;
        height: number;
    }>({ width: 0, height: 0 });

    let isEnter = false;

    const { presentationId } = useParams<{ presentationId: string }>();
    const history = useHistory();

    useEffect(() => {
        if (stageSize.width !== 0 && !isPresentationLoaded) {
            presentationAPIs
                .getPresentation(
                    presentationId,
                    stageSize.width,
                    stageSize.height
                )
                .then(({ data }) => {
                    setPages(data.pages);
                    setSelectedPage(data.pages[0]);
                    setPresentation(data);
                    setIsPresentationLoaded(true);
                })
                .catch((error) => {
                    if (
                        axios.isAxiosError(error) &&
                        error.response?.status === 403
                    ) {
                        toast.error('У Вас нет доступа к данному пациенту');
                        history.push(ROUTES.PRESENTATIONS.END);
                    }
                });
        }
    }, [presentationId, stageSize]);

    const updatePageFigures = useCallback(
        (
            pageId: string,
            figures: {
                lines?: ILine[];
                rectangles?: IRectangle[];
                arrows?: IArrow[];
                curves?: ILine[];
                dotted_lines?: ILine[];
            }
        ) => {
            setPages((prevState) =>
                prevState.map((page) => {
                    if (page.id === pageId) {
                        return {
                            ...page,
                            shapes: Object.assign(page.shapes, figures),
                        };
                    } else {
                        return page;
                    }
                })
            );
        },
        []
    );

    const gerOrCreateLink = async (presentation: string, patient: string) => {
        return doctorAPIs.getOrCreateLink(presentation, patient);
    };

    const openLink = async (presentation: string, patient: string) => {
        const link = gerOrCreateLink(presentation, patient);
        const url = await link;

        window.open(
            process.env.REACT_PUBLIC_APP_URL +
                '/presentations/' +
                url.data.link,
            '_blank'
        );
    };

    const setPageVisibility = useCallback(
        (pageId: string, isHidden: boolean) => {
            presentationAPIs
                .setPresentationPageVisibility(pageId, isHidden)
                .then((res) => {
                    setPages((prev) => {
                        return prev.map((page) => {
                            if (page.id === pageId) {
                                return {
                                    ...page,
                                    is_hidden: res.data.is_hidden,
                                };
                            } else {
                                return page;
                            }
                        });
                    });
                });
        },
        [setPages]
    );

    return (
        <div className={styles.store_root}>
            <div className={styles.store_header_container}>
                <div
                    onClick={history.goBack}
                    className={styles.store_header_back}
                />
                <p className={styles.store_title}>
                    {presentation?.name ?? 'Загрузка'}
                </p>
                {presentation != null && (
                    <div className="store__statusAndButton">
                        <div
                            className={
                                presentation?.state === 'Done'
                                    ? 'store_status'
                                    : 'store_status_working'
                            }
                        >
                            {presentation?.state === 'Done'
                                ? 'Готово'
                                : 'В работе'}
                        </div>
                        <div className="store__free-space" />
                        <div className="store__buttonContainer">
                            <Tutorial tutorial={'presentation'} />
                            <div
                                onClick={() =>
                                    openLink(
                                        presentationId,
                                        presentation?.patientId || ''
                                    )
                                }
                                onMouseEnter={() => {
                                    isEnter = true;
                                    setTimeout(() => {
                                        if (isEnter) setIsMessageVisible(true);
                                    }, 500);
                                }}
                                onMouseLeave={() => {
                                    isEnter = false;
                                    setIsMessageVisible(false);
                                }}
                            >
                                <Button
                                    title={'Открыть просмотр'}
                                    state={ButtonState.ACTIVE}
                                />
                            </div>
                            {isMessageVisible && (
                                <div className="pres_home_tip">
                                    Проверьте разрешение открывать <br />
                                    всплывающие окна
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <Alert
                title="Редактор презентации"
                description="Место где вы можете подправить положение и размеры фотографий пациента.<br/><br/>👆🏻 Выберите элемент чтобы начать с ним работу<br/>🔍 Используйте инструменты для работы с фотографией<br/>↕️ Используйте клавиши вниз вверх для быстрой навигации по страницам"
                id="all_presentations"
            />
            {presentation == null && <CustomLoader />}
            <div
                className="root"
                style={{ opacity: presentation == null ? 0 : 1 }}
            >
                <PresentationEditList
                    pages={pages}
                    selectedIndex={selectedPageIndex}
                    setSelectedPage={(index) => {
                        setSelectedPageIndex(index);
                        setSelectedPage({ ...pages[index] });
                    }}
                    setPageVisibility={setPageVisibility}
                />
                <PresentationEditPage
                    page={selectedPage}
                    updatePageFigures={updatePageFigures}
                    stageSize={stageSize}
                    setStageSize={setStageSize}
                />
                <FontLoader fonts={presentation?.importFonts ?? []} />
            </div>
        </div>
    );
};

export default HomePage;
